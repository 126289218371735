import { Wizard, useMutation, useRootStore } from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  Mutation,
  MutationUpdateControlUsersArgs,
  UpsEntity,
} from '../../../client/graphql';
import { updateControlUsersGql } from './gql/updateControlUser.gql';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';

export type UpdateControlUserWizardContext = {
  credentials: {
    username: string | null;
    password: string | null;
  };
  user: {
    _id: string | null;
    firstName: string | null;
    lastName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    ups: string[];
    lang: string;
  };
  listParking: Array<{
    code: UpsEntity['code'];
    _id: UpsEntity['_id'];
  }>;
  selectParking: {
    _id: string | null;
  };
};

export const UpdateControlUserWizard = () => {
  const { WizardStore } = useRootStore();

  const { credentials, user } = WizardStore.selected?.store;

  const [updateControlUsers, updateControlUsersState] = useMutation<
    Mutation['updateControlUsers'],
    MutationUpdateControlUsersArgs
  >(updateControlUsersGql);

  const state = useMemo(() => {
    if (updateControlUsersState.loading) return undefined;
    if (updateControlUsersState.error) return false;
    if (updateControlUsersState.data) return true;
  }, [updateControlUsersState]);

  return (
    <Wizard<UpdateControlUserWizardContext>
      config={{
        title: 'Modifier un agent',
        withValidation: {
          state,
          success: "La mise à jour de l'utilisateur a été réalisée !",
          error:
            "Une erreur est survenue lors de la mise à jour de l'utilisateur",
        },
      }}
      data={{
        defaultContext: {
          credentials: {
            username: credentials.username,
            password: credentials.password,
          },
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            fpsAgentId: user.fpsAgentId,
            fpsAgentName: user.fpsAgentName,
            lang: user.lang,
            ups: user.ups,
            _id: user._id,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ user, selectParking, credentials, listParking }) => {
          try {
            await updateControlUsers({
              variables: {
                input: {
                  firstName: user.firstName,
                  fpsAgentId: `${user.fpsAgentId}`,
                  fpsAgentName: user.fpsAgentName,
                  lang: user.lang,
                  lastName: user.lastName,
                  username: credentials.username!,
                  ups: user.ups!,
                  userId: user._id!,
                  password: credentials.password!,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
