
import { gql } from '@apollo/client'

export const updateTerminalActionGql = gql`
    mutation updateTerminalAction(
        $id: String!
        $terminalId: String
        $siteId: String
        $type: String
        $start: DateTime
        $deadline: DateTime
        $params: JSON
    ) {

        updateTerminalAction(
            id: $id
            terminalId: $terminalId
            siteId: $siteId
            type: $type
            start: $start
            deadline: $deadline
            params: $params
        ) {
            siteId
            terminalId
            type
            status
            start
            deadline
            params
        }

    }
`