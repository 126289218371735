import { gql } from '@apollo/client'

export const deleteTerminalActionGql = gql`
    mutation deleteTerminalAction(
        $id: String!
    ) {
        deleteTerminalAction(
            id: $id
        )
    }
`