import { DrawingManager } from '@react-google-maps/api';
import Color from 'color';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { getCSS } from '../../../functions/css.function';
import { upload } from '../../../functions/file.function';
import { useRootStore } from '../../../hook/useRootStore.hook';
import { useTranslation } from '../../../hook/useTranslation.hook';
import { Badge } from '../../badge/badge.component';
import { Button } from '../../button/button.component';
import { Popover } from '../../popover/popover.component';
import { useMapsTheme } from '../hook/theme.hook';
import { GeoJSONToOverlay, MapsContext, MapsType } from '../maps.component';

const overlayToGeoJson = <TProperties extends MapsType.PropertiesBase>(
  overlays: Array<MapsType.Overlay.Building>,
): MapsType.Data<TProperties> => {
  return {
    type: 'FeatureCollection',
    features: overlays
      .map(({ id, type, overlay }) => {
        switch (type) {
          case google.maps.drawing.OverlayType.POLYGON:
            const paths = overlay
              .getPaths()
              .getArray()
              .map((path) =>
                path.getArray().map(({ lat, lng }) => [lng(), lat()]),
              )
              .map((item) => [item]);

            const type =
              Array.isArray(paths) && paths.length === 1
                ? 'Polygon'
                : 'MultiPolygon';

            return {
              type: 'Feature',
              properties: overlay.get('allProperties'),
              geometry: {
                type: type,
                coordinates:
                  type === 'Polygon'
                    ? paths[0][0][0].every((item, index) => {
                        return (
                          item === paths[0][0][paths[0][0].length - 1][index]
                        );
                      })
                      ? paths[0]
                      : [[...paths[0][0], paths[0][0][0]]]
                    : paths,
              },
            } as GeoJSON.Feature<
              GeoJSON.Polygon | GeoJSON.MultiPolygon,
              TProperties
            >;

          default:
            return null;
        }
      })
      .filter((item) => !!item),
  };
};

const duplicateOverlay = ({
  id,
  type,
  overlay,
}: Pick<
  MapsType.Overlay.Building,
  'id' | 'type' | 'overlay'
>): MapsType.Overlay.Building => {
  switch (type) {
    case google.maps.drawing.OverlayType.POLYGON:
      const paths: Array<Array<{ lat: number; lng: number }>> = [];

      overlay
        .getPaths()
        .forEach((path, index) =>
          paths.push(
            path.getArray().map(({ lat, lng }) => ({ lat: lat(), lng: lng() })),
          ),
        );

      const duplicatePolygon = new google.maps.Polygon({
        fillColor: overlay.get('fillColor'),
        fillOpacity: overlay.get('fillOpacity'),
        strokeColor: overlay.get('strokeColor'),
        strokeOpacity: overlay.get('strokeOpacity'),
        strokeWeight: overlay.get('strokeWeight'),
        zIndex: overlay.get('zIndex'),
        visible: true,
        map: null,
      });

      duplicatePolygon.set('allProperties', overlay.get('allProperties'));
      duplicatePolygon.set('id', id);
      duplicatePolygon.setPaths(overlay.getPaths().getArray());
      duplicatePolygon.set('calcPath', paths);

      return {
        id,
        type,
        overlay: duplicatePolygon,
      };
  }
};

export const MapsDrawingModule = observer(() => {
  const {
    load,
    actions: {
      setCurrentInfoWindow,
      setMode,
      setOverlaysDrawing,
      setOverlaysImport,
      setSaveOverlayInitial,
      setSaveOverlayDelete,
      setAnOverlayIsEditing,
    },
    store: {
      currentInfoWindow,
      mode,
      overlaysDrawing,
      overlaysImport,
      saveOverlayInitial,
      saveOverlayDelete,
      anOverlayIsEditing,
    },
    modules: { drawing },
    overlaysInitial,
    map,
  } = useContext(MapsContext);

  const mapsTheme = useMapsTheme({ load });
  const { NotificationStore } = useRootStore();
  const { t, lang } = useTranslation();
  const drawingManagerRef = useRef<DrawingManager>(null);
  const isEdited =
    overlaysDrawing.length ||
    saveOverlayDelete.length ||
    overlaysImport.length ||
    anOverlayIsEditing;

  const allOverlays = [
    ...(overlaysImport || []),
    ...(overlaysDrawing || []),
    ...(overlaysInitial || []),
  ];

  const onCancel = useCallback(() => {
    overlaysInitial.forEach(({ id, type, overlay }) => {
      const overlayInitial = saveOverlayInitial.find((item) => item.id === id);

      const calcPath = overlayInitial?.overlay.get('calcPath');

      if (!overlayInitial) return;

      switch (type) {
        case google.maps.drawing.OverlayType.POLYGON:
          if (Array.isArray(calcPath) && calcPath.length === 1) {
            overlay.setPath(calcPath[0]);
          } else {
            overlay.setPaths(calcPath);
          }

          overlay.set(
            'allProperties',
            overlayInitial.overlay.get('allProperties'),
          );
          overlay.set('id', overlayInitial.id);
          overlay.setOptions({
            fillColor: overlayInitial.overlay.get('fillColor'),
            fillOpacity: overlayInitial.overlay.get('fillOpacity'),
            strokeColor: overlayInitial.overlay.get('strokeColor'),
            strokeOpacity: overlayInitial.overlay.get('strokeOpacity'),
            strokeWeight: overlayInitial.overlay.get('strokeWeight'),
            visible: true,
            strokePosition: overlayInitial.overlay.get('strokePosition'),
            zIndex: overlayInitial.overlay.get('zIndex'),
          });

          if (!overlay.getMap()) {
            overlay.setMap(map);
            overlay.setVisible(true);
          }
          break;
      }
    });

    overlaysDrawing.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    overlaysImport.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    setOverlaysDrawing([]);
    setSaveOverlayInitial([]);
    setSaveOverlayDelete([]);
    setOverlaysImport([]);

    setMode('read');
    setAnOverlayIsEditing(false);
  }, [
    overlaysDrawing,
    saveOverlayDelete,
    saveOverlayInitial,
    allOverlays,
    map,
  ]);

  const onSave = useCallback(() => {
    const idsOfDelete = saveOverlayDelete.filter(Boolean).map(({ id }) => id);

    const newList = allOverlays.filter(
      (element) => !idsOfDelete.includes(element.id),
    );

    drawing?.onSubmit?.(overlayToGeoJson(newList));

    overlaysDrawing.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    overlaysImport.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    saveOverlayDelete.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    saveOverlayInitial.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    overlaysInitial.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    setOverlaysDrawing([]);
    setOverlaysImport([]);
    setSaveOverlayInitial([]);
    setSaveOverlayDelete([]);

    setMode('read');
    setAnOverlayIsEditing(false);
  }, [
    overlaysDrawing,
    saveOverlayDelete,
    saveOverlayInitial,
    allOverlays,
    overlaysImport,
  ]);

  const onDeleteAll = useCallback(() => {
    allOverlays?.forEach(({ overlay }) => {
      overlay.setVisible(false);
      overlay.setMap(null);
    });

    if (map) {
      map.data.forEach((feature) => map.data.remove(feature));
      map.overlayMapTypes.clear();
    }

    drawing?.onSubmit?.(overlayToGeoJson([]));
  }, [map, allOverlays]);

  const onEdit = useCallback(() => {
    const duplicateList: MapsType.Overlay.Building[] = [];

    overlaysInitial?.forEach(({ id, type, overlay }) => {
      duplicateList.push(duplicateOverlay({ id, type, overlay }));
    });

    setSaveOverlayInitial(duplicateList || []);
    setMode('edit');
  }, [setMode, overlaysInitial]);

  const onImport = useCallback(
    async (files: FileList) => {
      const notificationKey = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'LOADING',
        content: t('watermelon-maps-import-loading'),
        key: notificationKey,
      });

      try {
        const content = await upload(files, 'string');

        if (content instanceof Error) {
          NotificationStore.emit({
            mode: 'ERROR',
            content: t('watermelon-maps-import-error'),
            key: notificationKey,
          });

          return;
        }

        const itemsParsed = (
          Array.isArray(content)
            ? content || [content as string]
            : [content as string]
        )
          .map((item) => JSON.parse(item as string))
          .reduce(
            (acc: GeoJSON.Feature<any, any>[], item) => [
              ...acc,
              ...item.features,
            ],
            [],
          )
          .map((item) => {
            if (item?.properties?._id) {
              delete item.properties._id;
            }

            return item;
          });

        const newOverlays = GeoJSONToOverlay(itemsParsed, mapsTheme);

        newOverlays.forEach(({ overlay }) => {
          overlay.setVisible(true);
          overlay.setMap(map);
          overlay.setEditable(true);
          overlay.setDraggable(true);
        });

        setOverlaysImport((prev) => [...prev, ...newOverlays]);

        NotificationStore.emit({
          mode: 'SUCCESS',
          content: t('watermelon-maps-import-success'),
          key: notificationKey,
        });
      } catch (error) {
        NotificationStore.emit({
          mode: 'ERROR',
          content: t('watermelon-maps-import-error'),
          key: notificationKey,
        });
      }
    },
    [NotificationStore, lang, setOverlaysImport],
  );

  const onExport = useCallback(() => {
    const notificationKey = crypto.randomUUID();
    NotificationStore.emit({
      mode: 'LOADING',
      content: t('watermelon-maps-export-loading'),
      key: notificationKey,
    });

    try {
      const idsOfDelete = saveOverlayDelete.map(({ id }) => id);

      const newList = allOverlays.filter(
        (element) => !idsOfDelete.includes(element.id),
      );

      const geoJson = overlayToGeoJson(newList);

      const blob = new Blob([JSON.stringify(geoJson, null, 2)], {
        type: 'application/json',
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export-${new Date().toISOString()}.geojson`;
      a.click();

      NotificationStore.emit({
        mode: 'SUCCESS',
        content: t('watermelon-maps-export-success'),
        key: notificationKey,
      });
    } catch (error) {
      NotificationStore.emit({
        mode: 'ERROR',
        content: t('watermelon-maps-export-error'),
        key: notificationKey,
      });
    }
  }, [allOverlays, saveOverlayDelete]);

  const countOverlays = useMemo(() => {
    const idsOfDelete = saveOverlayDelete.map(({ id }) => id);

    return allOverlays.filter((item) => !idsOfDelete.includes(item.id)).length;
  }, [allOverlays, saveOverlayDelete]);

  //! Activation des options d'édition et de déplacement des overlays
  useEffect(() => {
    allOverlays.forEach(({ overlay }) => {
      if (drawing) {
        const value = mode === 'edit';
        overlay.setEditable(value);
        overlay.setDraggable(value);
      }
    });
  }, [allOverlays, mode, drawing]);

  //! Activation des événements de l'overlay pour connaitre le changement de position ou de structure
  useEffect(() => {
    const listeners: Array<google.maps.MapsEventListener> = [];
    allOverlays.forEach(({ type, overlay }) => {
      switch (type) {
        case google.maps.drawing.OverlayType.POLYGON:
          overlay.getPaths().forEach((path) => {
            listeners.push(
              google.maps.event.addListener(path, 'set_at', () => {
                setAnOverlayIsEditing(true);
              }),
            );
            listeners.push(
              google.maps.event.addListener(path, 'insert_at', () => {
                setAnOverlayIsEditing(true);
              }),
            );
          });

          break;
      }
    });

    return () => {
      listeners.forEach((listener) => {
        google.maps.event.removeListener(listener);
      });
    };
  }, [allOverlays]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "T'es sûr de vouloir quitter ?";
    };

    if (isEdited) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (isEdited) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [isEdited]);

  return load && drawing ? (
    <>
      {mode === 'edit' && (
        <DrawingManager
          ref={drawingManagerRef}
          onOverlayComplete={(e) => {
            const type = e.type;
            const overlay = e.overlay;

            if (!overlay) return;

            overlay.set('id', crypto.randomUUID());
            overlay.setDraggable(true);

            switch (type) {
              case google.maps.drawing.OverlayType.POLYGON:
                (overlay as google.maps.Polygon).setEditable(true);
                (overlay as google.maps.Polygon).setDraggable(true);
                break;
            }

            const newOverlay = {
              type: e.type,
              overlay: e.overlay!,
              id: e.overlay?.get('id'),
            } as MapsType.Overlay.Building;

            setOverlaysDrawing((prev) => [...prev, newOverlay]);
          }}
          options={{
            drawingControl: true,
            circleOptions: {
              ...(mapsTheme?.drawingManagerStyle?.circleOptions || {}),
              editable: true,
              clickable: true,
              draggable: true,
            },
            polygonOptions: {
              ...(mapsTheme?.drawingManagerStyle?.polygonOptions || {}),
              editable: true,
              geodesic: true,
              clickable: true,
              draggable: true,
            },
            polylineOptions: {
              ...(mapsTheme?.drawingManagerStyle?.polylineOptions || {}),
              editable: true,
              geodesic: true,
              clickable: true,
              draggable: true,
            },
            rectangleOptions: {
              ...(mapsTheme?.drawingManagerStyle?.rectangleOptions || {}),
              editable: true,
              clickable: true,
              draggable: true,
            },
            markerOptions: {
              ...(mapsTheme?.drawingManagerStyle?.markerOptions || {}),
              crossOnDrag: true,
              draggable: true,
              clickable: true,
              icon: {
                url: 'https://cellar-c2.services.clever-cloud.com/yoonite-static/presto_one_marker.png',
                scaledSize: new google.maps.Size(70, 70),
              },
              label: {
                text: 'test',
                color: new Color(getCSS('--color-primary-over')).hex(),
                fontSize: '16px',
                className: 'maps-marker-label',
              },
              title: 'ddddd',
              optimized: true,
              zIndex: 1000,
            },
            drawingControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP,
              drawingModes: Object.entries(drawing?.modes || {})
                .filter(([_, value]) => !!value)
                .map(([key]) => key as MapsType.Overlay.Type),
            },
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          backgroundColor: 'transparent',

          // border: `1px solid ${getCSS('--color-border')}`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {mode === 'edit' && (
            <>
              <Button
                handleEvent={{
                  click: onCancel,
                }}
                config={{
                  text: 'Annuler',
                  mode: 'fill',
                  size: 'medium',
                  color: 'primary',
                }}
              />

              <Button
                config={{
                  text: 'Importer',
                  mode: 'inset',
                  size: 'medium',
                  color: 'primary',
                  type: {
                    value: 'upload',
                    upload: (event) => {
                      onImport(event);
                    },
                    accept: '.geojson',
                    multiple: true,
                  },
                }}
              />

              <Button
                handleEvent={{
                  click: onExport,
                }}
                config={{
                  text: 'Exporter',
                  mode: 'inset',
                  size: 'medium',
                  color: 'primary',
                }}
              />
              <Popover.Confirm
                config={{
                  title: 'Action importante',
                  description:
                    'Voulez-vous vraiment enregistrer les modifications ?',
                }}
                handleEvent={{
                  confirm: onSave,
                }}
              >
                <Button
                  config={{
                    text: 'Sauvegarder',
                    mode: 'inset',
                    color: 'primary',
                    size: 'medium',
                    disabled: !isEdited,
                  }}
                />
              </Popover.Confirm>

              <Popover.Confirm
                config={{
                  title: 'Action importante',
                  description:
                    'Voulez-vous vraiment supprimer toutes les zones ?',
                }}
                handleEvent={{
                  confirm: onDeleteAll,
                }}
              >
                <Button
                  config={{
                    text: 'Tout supprimer',
                    mode: 'inset',
                    color: 'error',
                    size: 'medium',
                  }}
                />
              </Popover.Confirm>
            </>
          )}
          {mode === 'read' && (
            <Button
              handleEvent={{
                click: onEdit,
              }}
              config={{
                text: 'Modifier',
                mode: 'fill',
                size: 'medium',
                color: 'primary',
              }}
            />
          )}
        </div>

        <div className="drawingEditBlock__state">
          <Badge
            config={{
              text: `${mode === 'edit' ? 'Edition' : 'Lecture'} (${
                countOverlays
              })`,
              size: 'xlarge',
              color: mode === 'edit' ? 'warn' : 'success',
            }}
          />
        </div>
      </div>
    </>
  ) : null;
});
