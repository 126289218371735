import { gql } from '@apollo/client';

export const findLayersGql = gql`
  query findLayers($clientId: String!) {
    findLayers(clientId: $clientId) {
      _id
      code
      category
      translation {
        lang
        name
        description
      }
      shape {
        type
        coordinates
      }
      properties {
        stroke
        strokeWidth
        strokeOpacity
        fill
        fillOpacity
      }
      level
      createdAt
      updatedAt
      tariffCode
    }
  }
`;
