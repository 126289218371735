import { gql } from '@apollo/client'

export const createTerminalActionGql = gql`
    mutation createTerminalAction(
        $terminalAction: CreateTerminalActionDto!
    ) {

        createTerminalAction(terminalAction: $terminalAction) {
            _id
            siteId
            terminalId
            type
            status
            start
            deadline
            params
        }

    }
`