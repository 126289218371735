import { useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoIEMAdmin from './assets/logo/iem-admin';

export const MainUse = observer(() => {
  const { SSOStore, MenuStore, WizardStore, I18NStore } = useRootStore();
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const me = SSOStore.user;

  useEffect(() => {
    MenuStore.setMenu([
      {
        key: '/onstreet',
        position: 'top',
        title: 'Presto One',
        items: [
          {
            key: '/onstreet/sites',
            label: 'Sites',
            icon: 'faMagnifyingGlassSolid',
          },
          {
            key: '/adm/service-cards',
            label: 'Service Cards',
            icon: 'faIdCardSolid',
          },
          {
            key: '/adm/buckets',
            label: 'Buckets',
            icon: 'faBucketSolid',
            items: [
              {
                key: '/adm/buckets',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/adm/fragments',
                label: 'Fragments',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
          {
            key: '/adm/terminal-actions',
            label: 'Terminal Actions',
            icon: 'faGearsSolid',
          },
        ],
      },
      {
        key: '/onstreet',
        position: 'top',
        title: 'Voirie',
        items: [
          {
            key: '/onstreet/clients',
            label: 'Clients',
            icon: 'faUserTieSolid',
            items: [
              {
                key: '/onstreet/clients',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/onstreet/sites',
                label: 'Sites',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/onstreet/ups',
                label: 'UPS',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
          {
            key: '/onstreet/parking-rights',
            label: 'Tickets',
            icon: 'faTicketSolid',
          },
          {
            key: '/onstreet/simulate-tariffs',
            label: 'Tarifs',
            icon: 'faCoinsSolid',
          },
          {
            key: '/onstreet/cartography',
            label: 'Cartographie',
            icon: 'faMapSolid',
          },
        ],
      },
      {
        key: '/offstreet',
        position: 'top',
        title: 'Ouvrage',
        items: [
          {
            key: '/offstreet/operators',
            label: 'Opérateurs',
            icon: 'faUserNurseSolid',
            items: [
              {
                key: '/offstreet/operators',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/offstreet/parkings',
                label: 'Parkings',
                icon: 'faMagnifyingGlassSolid',
              },
              {
                key: '/offstreet/sessions',
                label: 'Sessions',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
        ],
      },
      {
        key: '/prestoscan',
        position: 'top',
        title: 'Presto Scan',
        items: [
          {
            key: '/prestoscan/users',
            label: 'Agents',
            icon: 'faUserSecretSolid',
            items: [
              {
                key: '/prestoscan/users',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
          {
            key: '/prestoscan/cameras',
            label: 'Caméras',
            icon: 'faCameraSolid',
            items: [
              {
                key: '/prestoscan/cameras',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
        ],
      },
      {
        key: '/payments',
        position: 'top',
        title: 'Finances',
        items: [
          {
            key: '/payments/contracts',
            label: 'Contrats',
            icon: 'faAddressCardSolid',
            items: [
              {
                key: '/payments/contracts',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
          {
            key: '/payments/transactions',
            label: 'Transactions',
            icon: 'faCoinsSolid',
            items: [
              {
                key: '/payments/transactions',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
        ],
      },
      {
        key: '/adm',
        position: 'top',
        title: 'Admin',
        items: [
          {
            key: '/adm/translations',
            label: 'Traductions',
            icon: 'faLanguageSolid',
            items: [
              {
                key: '/adm/translations',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
          {
            key: '/mails',
            label: 'Hermes',
            icon: 'faDoveSolid',
            items: [
              {
                key: '/mails',
                label: 'Mails',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },

          {
            key: '/adm/admin-users',
            label: 'Users',
            icon: 'faUserSolid',
            items: [
              {
                key: '/adm/admin-users',
                label: 'Recherche',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },

          {
            key: '/adm/monitoring',
            label: 'Monitoring',
            icon: 'faTerminalSolid',
            items: [
              {
                key: '/adm/monitoring',
                label: 'Monitoring',
                icon: 'faMagnifyingGlassSolid',
              },
            ],
          },
        ],
      },
    ]);
  }, [lang]);

  useEffect(() => {
    I18NStore.updateLang('fr_FR');
  }, []);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          navigate(key);
        },
      }}
      config={{
        navigation: {
          logo: LogoIEMAdmin(),
          mode: 'drop',
        },
        header: {
          logo: IEM(),
          shortcut: [
            {
              label: 'Configuration PrestoScan',
              icon: 'customFaviconPrestoScan',
              event: () => {
                WizardStore.select({ id: 'createPrestoScan' });
              },
            },
            //! TODO : (MCLI_666)
            // {
            //   label: `Création d'un technicien PMS`,
            //   icon: 'faUserNurseSolid',
            //   event: () => {
            //     WizardStore.select({ id: 'createPmsTechnicalUser' });
            //   },
            // },
            {
              label: "Création d'un administrateur",
              icon: 'faUserSolid',
              event: () => {
                WizardStore.select({ id: 'createAdminUser' });
              },
            },
            {
              label: "Création d'un agent",
              icon: 'faUserSecretSolid',
              event: () => {
                WizardStore.select({ id: 'createControlUser' });
              },
            },
            {
              label: "Création d'un opérateur",
              icon: 'faUserTieSolid',
              event: () => {
                WizardStore.select({ id: 'createOperator' });
              },
            },
            {
              label: "Création d'une caméra",
              icon: 'faCameraSolid',
              event: () => {
                WizardStore.select({ id: 'createCamera' });
              },
            },
            {
              label: "Création d'un parking",
              icon: 'faParkingSolid',
              event: () => {
                WizardStore.select({ id: 'createParking' });
              },
            },
            //! TODO : PSP required (MCLI_666)
            // {
            //   label: "Création d'un client",
            //   icon: 'faTreeCitySolid',
            //   event: () => {
            //     WizardStore.select({ id: 'createClient' });
            //   },
            // },
            {
              label: "Création d'un site",
              icon: 'faLocationDotSolid',
              event: () => {
                WizardStore.select({ id: 'createSite' });
              },
            },
            //! TODO : Pas fini
            // {
            //   label: 'Création un utilisateur',
            //   event: () => {
            //     wizardService.send('SHOW_WIZARD', { id: 'createUser' });
            //   },
            // },
            {
              label: "Création d'un tarif",
              icon: 'faCoinsSolid',
              event: () => {
                WizardStore.select({ id: 'createTariff' });
              },
            },
          ],
        },
      }}
    />
  );
});

export default MainUse;
