import { Button, Empty, Line, Row, Space, Zone } from '@gimlite/watermelon';
import { SkeletonBlock } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { DateTime, Duration } from 'luxon';
import { useMemo, useState } from 'react';

type dtEnum = 'p' | 'f' | 'np' | 'b';

const dtMapper = {
  // Paying
  p: {
    width: 3,
    color: '#00a8e8',
  },

  // Free
  f: {
    width: 3,
    color: '#2dc653',
  },

  // Not paying
  np: {
    width: 1,
    color: '#adb5bd',
  },

  // Banned
  b: {
    width: 3,
    color: '#d00000',
  },
} as const satisfies Record<dtEnum, { width: number; color: string }>;

const msToDuration = (ms: number): string => {
  const duration = Duration.fromMillis(ms).rescale();

  for (const unit of [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  ]) {
    const value = duration.get(unit as any);
    if (value >= 1) {
      return `${Math.round(value)} ${unit}`;
    }
  }

  return 'Maintenant';
};

const msToDate = (ms: number, date: Date): string => {
  return DateTime.fromJSDate(date)
    .plus({ milliseconds: ms })
    .toFormat('dd/MM/yyyy HH:mm:ss');
};

export declare namespace TariffChartType {
  type Props = {
    data: Array<TariffChartType.Point>;
    config: {
      currency: string;
      now: Date;
    };
  };

  type Point = {
    date: number;
    n: string | null;
    dbg: Array<string> | null;
    a: number | null;
    l: boolean | null;
    m: string | null;
    dt: string | null;
    skipPoint?: boolean;
  };
}

export const TariffChart = ({ data, config }: TariffChartType.Props) => {
  const [pointRadius, setPointRadius] = useState(4);

  const dataFormatted = useMemo(() => {
    if (!data) return;

    const dataTransform = data.reduce(
      (acc: Array<TariffChartType.Point>, item, index) => {
        if (index === 0) return [item];

        if (!item.l && index !== data.length - 1) {
          acc.push(item);
          acc.push({ ...item, a: data[index + 1].a, skipPoint: true });
        } else {
          acc.push(item);
        }

        return acc;
      },
      [],
    );

    return [
      { ...dataTransform[0], date: 0, a: 0 },
      ...dataTransform.map((item, index) => {
        if (index === dataTransform.length - 1) return item;

        return {
          ...dataTransform[index + 1],
          a: item.a,
          date: item.date,
        };
      }),
    ].sort((a, b) => a.date - b.date);
  }, [data]);

  if (!data || !dataFormatted) {
    return <SkeletonBlock />;
  }

  if (data.length <= 1)
    return (
      <Empty
        config={{
          mode: {
            name: 'noData',
          },
        }}
      ></Empty>
    );

  return (
    <Zone
      config={{
        zones: [['filters'], ['chart']],
        rows: ['min-content', '1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'filters' }}>
        <Row>
          <Button
            handleEvent={{ click: () => setPointRadius(4) }}
            config={{
              text: 'Avec point',
              color: pointRadius ? 'primary' : 'yang',
              size: 'small',
            }}
          ></Button>
          <Space config={{ way: 'horizontal' }} />
          <Button
            handleEvent={{ click: () => setPointRadius(0) }}
            config={{
              text: 'Sans point',
              color: !pointRadius ? 'primary' : 'yang',
              size: 'small',
            }}
          ></Button>
        </Row>
      </Zone.Area>
      <Zone.Area config={{ area: 'chart' }}>
        <Line
          style={{
            height: '100%',
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                    modifierKey: 'ctrl',
                  },
                  drag: {
                    enabled: true,
                    modifierKey: 'ctrl',
                  },
                  mode: 'x',
                  scaleMode: 'y',
                },
                limits: {
                  x: {
                    max: dataFormatted[dataFormatted.length - 1].date,
                    min: dataFormatted[0].date,
                  },
                },
              },
              tooltip: {
                intersect: false,
                mode: 'index',
                callbacks: {
                  label: (context) => {
                    return '';
                  },
                  afterLabel: (context) => {
                    return [
                      ...(dataFormatted[context.dataIndex!]?.dbg || []).map(
                        (item) => `${item}`,
                      ),
                    ].filter((item) => item !== null);
                  },
                  footer: (context) => {
                    return dataFormatted[context[0].dataIndex!]?.m
                      ? `${dataFormatted[context[0].dataIndex!].m}`
                      : '';
                  },
                  title: (context) => {
                    return dataFormatted[context[0].dataIndex!]?.n
                      ? `${dataFormatted[context[0].dataIndex!].n}`
                      : '';
                  },
                },
                position: 'nearest',
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'timeseries',
                ticks: {
                  callback: (value, index, ticks) => {
                    return [
                      msToDuration(value as number),
                      msToDate(value as number, config.now),
                    ];
                  },
                },
              },
              currency: {
                ticks: {
                  callback: (value, index, ticks) =>
                    `${value} ${config.currency}`,
                },
                position: 'left',
              },
            },
          }}
          data={{
            labels: dataFormatted.map(({ date }) => date),
            datasets: [
              {
                yAxisID: 'currency',
                label: 'a',
                data: dataFormatted.map(({ a }) => a),
                spanGaps: false,
                borderWidth: 1,
                order: 0,
                pointRadius: dataFormatted.map((item) =>
                  item?.skipPoint ? 0 : pointRadius,
                ),
                segment: {
                  borderColor: (ctx) => {
                    return dataFormatted[ctx.p0DataIndex!]?.dt
                      ? dtMapper[dataFormatted[ctx.p0DataIndex!].dt as dtEnum]
                          .color
                      : '#ffffff';
                  },
                  borderWidth: (ctx) => {
                    return dataFormatted[ctx.p0DataIndex!]?.dt
                      ? dtMapper[dataFormatted[ctx.p0DataIndex!].dt as dtEnum]
                          .width
                      : 0;
                  },
                },
              },
            ],
          }}
        ></Line>
      </Zone.Area>
    </Zone>
  );
};
