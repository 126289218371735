import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useMemo } from 'react';
import { Mutation, MutationCreateTariffArgs } from '../../../client/graphql';
import { createTariffGql } from './gql/createTariff.gql';
import { InformationStep } from './steps/information.step';
import { EditorStep } from './steps/editor.step';
export type CreateTariffWizardContext = {
  description: string | null;
  code: string | null;
  clientId: string | null;
  translation: Array<{
    description: string | null;
    lang: string;
    name: string;
  }>;
};

export const CreateTariffWizard = () => {
  const { WizardStore } = useRootStore();

  const [createTariff, createTariffState] = useMutation<
    Mutation['createTariff'],
    MutationCreateTariffArgs
  >(createTariffGql);

  const state = useMemo(() => {
    if (createTariffState.loading) return undefined;
    if (createTariffState.error) return false;
    if (createTariffState.data) return true;
  }, [createTariffState]);

  return (
    <Wizard<CreateTariffWizardContext>
      config={{
        title: 'Créer un tarif',
        withValidation: {
          state,
          success: 'Le tarif a été créé avec succès',
          error: 'Une erreur est survenue lors de la création du tarif',
        },
      }}
      data={{
        defaultContext: {
          description: null,
          code: null,
          clientId: null,
          translation: [],
        },
      }}
      handleEvent={{
        done: async (payload) => {
          try {
            const { description, code, clientId, translation } = payload;

            await createTariff({
              variables: {
                input: {
                  description: description!,
                  code: code!,
                  clientId: clientId!,
                  translation: translation!,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Informations', component: InformationStep }}
      />
      <Wizard.Item config={{ title: 'Editeur', component: EditorStep }} />
    </Wizard>
  );
};
