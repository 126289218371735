import { gql } from '@apollo/client';

export const tariffsGql = gql`
  query searchTariffs {
    searchTariffs {
      list {
        _id
        clientId
        code
        translation {
          lang
          name
        }
        createdAt
        updatedAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
