import { ColorPicker as ColorPickerAnt } from 'antd';
import { AggregationColor } from 'antd/es/color-picker/color';
import { ColorFormatType } from 'antd/es/color-picker/interface';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import type { Size as GlobalSize } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import './colorpicker.component.scss';

export declare namespace ColorPickerType {
  type Props = {
    className?: string;
    data?: {
      value?: ColorPickerType.Data.Value;
      defaultValue?: ColorPickerType.Data.Value;
    };
    config?: {
      disabled?: boolean;
      showText?: boolean | ((value: ColorPickerType.Data.Value) => boolean);
      allowClear?: boolean;
      format?: ColorFormatType;
      size?: ColorPickerType.Config.Size;
    };
    handleEvent?: {
      input?: (value: ColorPickerType.Data.Value) => void;
    };
  };

  namespace Data {
    type Value = string | null;
  }

  namespace Config {
    type Size = Extract<GlobalSize, 'small' | 'medium' | 'large'>;
  }
}

export const ColorPicker = ({
  data: { defaultValue, value } = {},
  handleEvent: { input } = {},
  config: {
    disabled = false,
    showText = true,
    allowClear = true,
    format = 'hex',
    size = 'medium',
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<ColorPickerType.Props>) => {
  const colorMapper = {
    small: 'small',
    medium: undefined,
    large: 'large',
  } as const satisfies Record<ColorPickerType.Config.Size, SizeType>;

  const formatMapper = (value: AggregationColor) => {
    switch (format) {
      case 'hex':
        return value.toHexString();
      case 'rgb':
        return value.toRgbString();
      case 'hsb':
        return value.toHsbString();
    }
  };

  return (
    <Form.Context.Consumer>
      {(form) => (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`
            colorpicker 
            ${className}
        `}
        >
          <ColorPickerAnt
            format={format}
            onChange={(value) => {
              const formattedValue = formatMapper(value);
              input?.(formattedValue);
              onChangeFormItem?.(formattedValue);
            }}
            className={`colorpicker__item`}
            defaultValue={defaultValue}
            value={valueFormItem ?? value}
            disabled={disabled}
            {...formItemParams}
            showText={
              typeof showText === 'function'
                ? (value) => showText(formatMapper(value))
                : showText
            }
            allowClear={allowClear}
            size={colorMapper[size]}
          />
        </div>
      )}
    </Form.Context.Consumer>
  );
};
