import { gql } from '@apollo/client';

export const simulateTariffGql = gql`
  query simulateTariff($input: SimulateTariffDto!) {
    simulateTariff(input: $input) {
      now
      table {
        n
        dbg
        d
        a
        l
        dt
        m
      }
    }
  }
`;
