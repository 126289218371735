import {
  AppLoaderEnd,
  AppLoaderStart,
  ConfigAnt,
  ConfigGQL,
  ConfigI18N,
  ConfigMaps,
  ConfigMenu,
  ConfigNotification,
  ConfigRoot,
  ConfigSSO,
  ConfigTheme,
  ConfigWizard,
  Keycloak,
} from '@gimlite/watermelon';
import { ConfigApp } from '@gimlite/watermelon/store/app.store';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import IEMSmallLogo from './assets/logo/IEM_small';
import config from './config';
import { AddParkingInControlUserWizard } from './wizards/addParkingInControlUser/addParkingInControlUser.wizard';
import { CreateAdminUserWizard } from './wizards/createAdminUser/createAdminUser.wizard';
import { CreateCameraWizard } from './wizards/createCamera/createCamera.wizard';
import { CreateClientWizard } from './wizards/createClient/createClient.wizard';
import { CreateControlUserWizard } from './wizards/createControlUser/createControlUser.wizard';
import { CreateOperatorWizard } from './wizards/createOperator/createOperator.wizard';
import { CreatePmsTechnicalUserWizard } from './wizards/createPmsTechnicalUser/createPmsTechnicalUser.wizard';
import { CreatePrestoScanWizard } from './wizards/createPrestoScan/createPrestoScan.wizard';
import { CreateSiteWizard } from './wizards/createSite/createSite.wizard';
import { CreateUserWizard } from './wizards/createUser/createUser.wizard';
import { UpdateAdminUserWizard } from './wizards/updateAdminUser/updateAdminUser.wizard';
import { UpdateCameraWizard } from './wizards/updateCamera/updateCamera.wizard';
import { UpdateControlUserWizard } from './wizards/updateControlUser/updateControlUser.wizard';
import { UpdateOperatorWizard } from './wizards/updateOperator/updateOperator.wizard';
import { UpdateSiteWizard } from './wizards/updateSite/updateSite.wizard';
import { CreateTariffWizard } from './wizards/createTariff/createTariff.wizard';

const root = createRoot(document.getElementById('root') as HTMLElement);

const { ENV, VERSION_FRONT, BFF_HOST, BFF_WS_HOST, GOOGLE_MAPS_API_KEY } =
  config;

const defaultTheme = 'dark';

root.render(
  <ConfigRoot>
    <AppLoaderStart config={{ logo: IEMSmallLogo(), defaultTheme }}>
      <ConfigApp
        debug={false}
        favicon={
          'https://cellar-c2.services.clever-cloud.com/yoonite-resources/default/iem.svg'
        }
        version={{
          env: config?.ENV,
          front: VERSION_FRONT,
        }}
      >
        <ConfigGQL
          uri={BFF_HOST}
          headers={{
            'x-access-token': config.BFF_TOKEN,
          }}
        >
          <ConfigSSO
            mode={{ type: 'children', parentUrl: config.SSO_PARENT_URL }}
            instance={
              new Keycloak({
                url: config.SSO_URL,
                realm: config.SSO_REALM,
                clientId: config.SSO_CLIENT_ID,
              })
            }
          >
            <ConfigI18N>
              <ConfigWizard
                registry={[
                  {
                    id: 'createSite',
                    component: CreateSiteWizard,
                  },
                  {
                    id: 'updateSite',
                    component: UpdateSiteWizard,
                  },
                  {
                    id: 'createClient',
                    component: CreateClientWizard,
                  },
                  {
                    id: 'createOperator',
                    component: CreateOperatorWizard,
                  },
                  {
                    id: 'updateOperator',
                    component: UpdateOperatorWizard,
                  },
                  {
                    id: 'createControlUser',
                    component: CreateControlUserWizard,
                  },
                  {
                    id: 'createPmsTechnicalUser',
                    component: CreatePmsTechnicalUserWizard,
                  },
                  {
                    id: 'createAdminUser',
                    component: CreateAdminUserWizard,
                  },
                  {
                    id: 'updateAdminUser',
                    component: UpdateAdminUserWizard,
                  },
                  {
                    id: 'createPrestoScan',
                    component: CreatePrestoScanWizard,
                  },
                  {
                    id: 'createCamera',
                    component: CreateCameraWizard,
                  },
                  {
                    id: 'updateCamera',
                    component: UpdateCameraWizard,
                  },
                  {
                    id: 'updateControlUser',
                    component: UpdateControlUserWizard,
                  },
                  {
                    id: 'addParkingInControlUser',
                    component: AddParkingInControlUserWizard,
                  },
                  {
                    id: 'createUser',
                    component: CreateUserWizard,
                  },
                  {
                    id: 'createTariff',
                    component: CreateTariffWizard,
                  },
                ]}
              >
                <ConfigMenu>
                  <ConfigNotification>
                    <ConfigTheme
                      color={{
                        dark: '#5dbed2',
                        light: '#0a2652',
                      }}
                      defaultTheme={defaultTheme}
                    >
                      <ConfigMaps token={GOOGLE_MAPS_API_KEY}>
                        <ConfigAnt>
                          <AppLoaderEnd>
                            <CustomRouter />
                          </AppLoaderEnd>
                        </ConfigAnt>
                      </ConfigMaps>
                    </ConfigTheme>
                  </ConfigNotification>
                </ConfigMenu>
              </ConfigWizard>
            </ConfigI18N>
          </ConfigSSO>
        </ConfigGQL>
      </ConfigApp>
    </AppLoaderStart>
  </ConfigRoot>,
);
