import {
  Badge,
  Button,
  Col,
  ColorPicker,
  Form,
  Icon,
  Input,
  Popover,
  Row,
  Select,
  TextArea,
  toCapitalizeCase,
  Write,
} from '@gimlite/watermelon';
import { useState } from 'react';
import './zone-modal.component.scss';

export declare namespace ZoneModalType {
  type Props = {
    className?: string;
    fillColor?: string | null;
    strokeColor?: string | null;
    fillOpacity?: number | null;
    strokeOpacity?: number | null;
    strokeWeight?: number | null;
    zoneName?: string | null;
    zoneCode?: string | null;
    tariffCode?: string | null;
    description?: string | null;
    type: string;
    zIndex?: number;
    mode: 'edit' | 'read';
    tariffList: Array<{
      name: string;
      code: string;
    }>;
    onChange?: (
      value: Pick<
        ZoneModalType.Props,
        | 'fillColor'
        | 'strokeColor'
        | 'fillOpacity'
        | 'strokeOpacity'
        | 'strokeWeight'
        | 'zoneName'
        | 'zoneCode'
        | 'tariffCode'
      > & { description?: string | null },
    ) => void;
    onClose?: () => void;
    onDelete?: () => void;
  };
}

const Close = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div onClick={onClick} className="zoneModalClose">
      <Icon config={{ type: 'faXmarkSolid', size: 'xmedium' }} />
    </div>
  );
};

export const ZoneModal = ({
  className = '',
  fillColor,
  strokeColor,
  fillOpacity,
  strokeOpacity,
  strokeWeight,
  zoneName,
  zoneCode,
  tariffCode,
  description,
  type,
  mode,
  tariffList,
  zIndex,
  onDelete,
  onChange,
  onClose,
}: ZoneModalType.Props) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const tariff = tariffList.find((tariff) => tariff.code === tariffCode);
  return mode === 'read' ? (
    <div className={`${className} zoneModalRead`}>
      <div className="zoneModalRead__head">
        <div className="zoneModalRead__head__present">
          <div
            className="zoneModalRead__head__present__color"
            style={{
              backgroundColor: fillColor || 'transparent',
              opacity: fillOpacity || 1,
              border: `${strokeWeight ? (strokeWeight > 4 ? 4 : strokeWeight) : 1}px solid ${strokeColor}`,
            }}
          ></div>

          <div className="zoneModalRead__head__present__text">
            <div className="zoneModalRead__head__present__text__name">
              <Write
                data={{ item: zoneName || 'Pas de nom' }}
                config={{
                  mode: 'title-small',
                  color: zoneName ? 'yang' : 'disabled',
                }}
              ></Write>

              <Badge
                config={{
                  text: toCapitalizeCase(type),
                  color: 'disabled',
                  size: 'small',
                }}
              />
            </div>

            <Write
              data={{
                item: tariff?.name || 'Pas de tarif',
              }}
              config={{
                mode: 'value-small',
                color: tariff ? 'yang' : 'disabled',
              }}
            ></Write>
          </div>
        </div>

        <div className="zoneModalRead__head__type">
          <Close onClick={onClose} />
        </div>
      </div>

      {typeof description === 'string' ? (
        <Write
          data={{ item: description }}
          config={{ mode: 'value-small' }}
        ></Write>
      ) : (
        description
      )}
    </div>
  ) : (
    <div className={`${className} zoneModalEdit`}>
      <div className="zoneModalEdit__head">
        <div className="zoneModalEdit__head__title">
          <Write
            data={{ item: 'Modifier la zone' }}
            config={{ mode: 'title-small' }}
          ></Write>

          <Badge
            config={{
              text:
                isValid === null
                  ? 'En attente'
                  : isValid
                    ? 'Valide'
                    : 'Invalide',
              color:
                isValid === null ? 'disabled' : isValid ? 'success' : 'error',
              size: 'medium',
            }}
          ></Badge>
        </div>
        <Close onClick={onClose} />
      </div>

      <Form
        config={{
          validateOnChange: true,
          element: {
            width: 'full',
            height: 'small',
          },
        }}
        data={{
          defaultValue: {
            zoneName,
            zoneCode,
            tariffCode,
            description,
            fillColor,
            strokeColor,
            fillOpacity,
            strokeOpacity,
            strokeWeight,
            zIndex,
          },
        }}
        handleEvent={{
          change: (value) => {
            onChange?.(
              Object.fromEntries(
                Object.entries(value).map(([key, value]) => [
                  key,
                  typeof value === 'string' && !value ? null : value,
                ]),
              ),
            );
          },
          validate: (value) => {
            setIsValid(value);
          },
        }}
      >
        <Col config={{ gap: 15 }}>
          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'zoneName',
                label: 'Nom de la zone',
                rules: [{ required: true }],
              }}
            >
              <Input
                config={{
                  placeholder: 'Zone verte',
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'zoneCode',
                label: 'Code de la zone',
                rules: [{ required: true }],
              }}
            >
              <Input
                config={{
                  placeholder: '123456',
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'tariffCode',
              label: 'Nom du tarif',
            }}
          >
            <Select
              data={{
                items: tariffList.map((tariff) => ({
                  label: tariff.name,
                  value: tariff.code,
                })),
              }}
            />
          </Form.Item>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'description',
              label: 'Description',
              rules: [{ required: false }],
            }}
          >
            <TextArea
              config={{
                placeholder: 'Ecrivez ici la description de la zone',
              }}
            />
          </Form.Item>

          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'fillColor',
                label: 'Couleur de fond',
              }}
            >
              <ColorPicker
                config={{
                  size: 'small',
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'strokeColor',
                label: 'Couleur de bordure',
              }}
            >
              <ColorPicker
                config={{
                  size: 'small',
                }}
              />
            </Form.Item>
          </Row>

          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'fillOpacity',
                label: 'Opacité de fond',
              }}
            >
              <Input
                config={{
                  width: 'full',
                  type: { name: 'number', min: 0, max: 1, step: 0.1 },
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'strokeWeight',
                label: 'Largeur de bordure',
              }}
            >
              <Input
                config={{
                  width: 'full',
                  type: { name: 'number', min: 0, max: 4, step: 1 },
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'zIndex',
              label: "Ordre d'affichage",
            }}
          >
            <Input
              config={{
                width: 'full',
                type: { name: 'number', min: 0, max: 10000, step: 1 },
              }}
            />
          </Form.Item>
        </Col>
      </Form>

      <Row config={{ gap: 10, horizontal: 'end' }}>
        <Popover.Confirm
          handleEvent={{
            confirm: () => {
              onDelete?.();
            },
          }}
          config={{
            title: 'Action importante',
            description: 'Voulez-vous vraiment supprimer cette zone ?',
          }}
        >
          <Button
            config={{ color: 'error', text: 'Supprimer', size: 'medium' }}
          ></Button>
        </Popover.Confirm>
      </Row>
    </div>
  );
};
