import {
  AutoComplete,
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  SelectType,
  useLazyQuery,
  WizardItemType,
  Write,
  Zone,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import {
  CameraPositionEnum,
  Query,
  QueryUpsArgs,
  QueryUpsListArgs,
} from '../../../../client/graphql';
import {
  CreatePrestoScanWizardContext,
  langsItemsSelect,
} from '../createPrestoScan.wizard';
import { upsGql } from '../gql/ups.gql';
import { upsListGql } from '../gql/upsList.gql';
import './general.scss';

export const UpsStep = ({
  context: {
    listParking,
    modeParking,
    searchParking,
    createParking,
    searchClient,
    createVAO,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const [upsList, upsListState] = useLazyQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    notification: {
      error: 'Erreur lors de la recherche des parkings',
    },
  });

  const [ups, upsState] = useLazyQuery<{ ups: Query['ups'] }, QueryUpsArgs>(
    upsGql,
    {
      notification: {
        error: 'Erreur lors de la lecture du parking',
      },
    },
  );

  const formattedTranslateLang = useMemo(
    () =>
      langsItemsSelect.map(
        ({ value: langBase, label }: SelectType.Data.Item) => ({
          value: langBase,
          label,
          disabled: searchParking?.translation
            ? searchParking?.translation
                .filter(Boolean)
                .map((translate) => translate.lang)
                .includes(langBase as string)
            : false,
        }),
      ),
    [searchParking],
  );

  const visibilityForm = useMemo(() => {
    return (
      searchParking?._id &&
      !!listParking.find((element) => element._id === searchParking._id)
    );
  }, [searchParking]);

  useEffect(() => {
    if (upsListState.data) {
      submit({
        listParking: upsListState.data.upsList.list,
      });
    }
  }, [upsListState.data]);

  useEffect(() => {
    if (upsState.loading) return;

    if (upsState.data) {
      const {
        code,
        _id,
        address,
        center,
        config,
        translation,
        cameras,
        occupancy,
        capacity,
      } = upsState.data.ups;

      submit({
        searchParking: {
          _id: _id,
          code: code,
          addressCountry: address?.addressCountry || null,
          addressLocality: address?.addressLocality || null,
          addressSubRegion: address?.addressSubRegion || null,
          postOfficeBoxNumber: address?.postOfficeBoxNumber || null,
          streetName: address?.streetName || null,
          specialPlace: address?.specialPlace || null,
          streetNumber: address?.streetNumber || null,
          streetNumberBis: address?.streetNumberBis || null,
          postalCode: address?.postalCode || null,
          streetType: address?.streetType || null,
          capacity,
          occupancy,
          latitude: center ? center[0] : null,
          longitude: center ? center[1] : null,
          translation: (translation || []).map(
            ({ lang, description, name }) => ({
              lang,
              description: description || null,
              name: name || null,
            }),
          ),
          terminalId: config?.prestoScan?.terminalId || null,
        },
        cameras: cameras.map(({ _id, position, code, description }) => ({
          _id,
          position: position as CameraPositionEnum,
          code,
          description: description || null,
        })),
        camerasSave: Object.freeze(
          cameras.map(({ _id, position, code, description }) => ({
            _id,
            position: position as CameraPositionEnum,
            code,
            description: description || null,
          })),
        ),
        createVAO: {
          sitesLegacyId:
            config?.prestoScan?.sitesLegacyId ||
            createVAO.sitesLegacyId ||
            null,
          rightsLegacyIds:
            config?.prestoScan?.rightsLegacyIds
              ?.filter(Boolean)
              ?.map((id) => ({ id })) ||
            createVAO?.rightsLegacyIds ||
            null,
          deletionDelay_complete:
            config?.prestoScan?.deletionDelay?.complete ||
            createVAO.deletionDelay_complete ||
            null,
          deletionDelay_fined:
            config?.prestoScan?.deletionDelay?.fined ||
            createVAO.deletionDelay_fined ||
            null,
          deletionDelay_incomplete:
            config?.prestoScan?.deletionDelay?.incomplete ||
            createVAO.deletionDelay_incomplete ||
            null,
          deletionDelay_toCheck:
            config?.prestoScan?.deletionDelay?.toCheck ||
            createVAO.deletionDelay_toCheck ||
            null,
          deletionDelay_unfined:
            config?.prestoScan?.deletionDelay?.unfined ||
            createVAO.deletionDelay_unfined ||
            null,
          entryTolerancePeriod:
            config?.prestoScan?.entryTolerancePeriod || null,
          exitTolerancePeriod: config?.prestoScan?.exitTolerancePeriod || null,
          isVerbalizationAllowed:
            config?.prestoScan?.isVerbalizationAllowed || null,
          outrageTreshold_danger:
            config?.prestoScan?.outrageTreshold?.danger || null,
          outrageTreshold_warning:
            config?.prestoScan?.outrageTreshold?.warning || null,
          showOccupancy: config?.prestoScan?.showOccupancy || null,
          maxSessionDurationInHours:
            config?.prestoScan?.maxSessionDurationInHours || 24,
        },
        FPS: {
          addressCountry:
            config?.fps?.recourseOrganization?.address?.addressCountry || null,
          addressLocality:
            config?.fps?.recourseOrganization?.address?.addressLocality || null,
          addressSubRegion:
            config?.fps?.recourseOrganization?.address?.addressLocality || null,
          postOfficeBoxNumber:
            config?.fps?.recourseOrganization?.address?.postOfficeBoxNumber ||
            null,
          streetName:
            config?.fps?.recourseOrganization?.address?.streetName || null,
          postalCode:
            config?.fps?.recourseOrganization?.address?.postalCode || null,
          streetNumber:
            config?.fps?.recourseOrganization?.address?.streetNumber || null,
          streetType:
            config?.fps?.recourseOrganization?.address?.streetType || null,
          streetNumberBis:
            config?.fps?.recourseOrganization?.address?.streetNumberBis || null,
          specialPlace:
            config?.fps?.recourseOrganization?.address?.specialPlace || null,
          reducedPriceDuration: config?.fps?.reducedPriceDuration || null,
          reducedFinePrice: config?.fps?.reducedFinePrice || null,
          finePrice: config?.fps?.finePrice || null,
          organizationId:
            config?.fps?.recourseOrganization?.organizationId || null,
          url: config?.fps?.recourseOrganization?.url || null,
          name: config?.fps?.recourseOrganization?.name || null,
          notificationAuthority: config?.fps?.notificationAuthority || null,
        },
      });
    } else if (upsState.error) {
      submit({
        searchParking: {
          _id: null,
          code: null,
          addressCountry: null,
          addressLocality: null,
          addressSubRegion: null,
          postOfficeBoxNumber: null,
          streetName: null,
          specialPlace: null,
          streetNumber: null,
          streetNumberBis: null,
          postalCode: null,
          streetType: null,
          capacity: null,
          latitude: null,
          longitude: null,
          occupancy: null,
          terminalId: null,
          translation: [],
        },
      });
    }
  }, [upsState.data, upsState.loading, upsState.error, upsState.count]);

  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['form']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'form' }}>
          {modeParking === 'search' && (
            <Form
              data={{ value: searchParking }}
              config={{
                validateOnChange: true,
              }}
              handleEvent={{
                change: (
                  value: CreatePrestoScanWizardContext['searchParking'],
                ) => {
                  if (
                    searchParking?.code &&
                    !!listParking.find(
                      (element) => element.code === value?.code,
                    )
                  ) {
                    submit({
                      searchParking: { ...searchParking, ...value },
                    });
                  }
                },
                validate: (value) => {
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'code',
                    label: 'UPS du parking dans Yoonite (source du tarif)',
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    handleEvent={{
                      input: (value) => {
                        upsList({
                          variables: {
                            codePattern: value,
                            clientId: searchClient?._id,
                          },
                        });

                        submit({
                          searchParking: null,
                        });
                      },
                      select(item) {
                        if (item?.value) {
                          ups({
                            variables: { upsId: item.value },
                          });
                        }
                      },
                    }}
                    data={{
                      items: listParking.map(({ code, _id }) => ({
                        value: _id,
                        label: code,
                      })),
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'plan-les-ouates-a1',
                    }}
                  />
                </Form.Item>

                <div
                  style={{
                    display: visibilityForm ? 'block' : 'none',
                    transition: 'none',
                  }}
                >
                  <Col config={{ gap: 30, width: 'full', vertical: 'center' }}>
                    <Col config={{ gap: 20, width: 'full' }}>
                      <Write
                        data={{ item: 'Nom du parking dans le menu' }}
                        config={{
                          mode: 'title-small',
                        }}
                      ></Write>
                      <Form.List
                        config={{
                          name: 'translation',
                        }}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <Col config={{ gap: 20, width: 'full' }}>
                            {fields.map(
                              ({ name, key, ...restOfField }: any, index) => {
                                return (
                                  <Row
                                    key={key}
                                    config={{
                                      vertical: 'end',
                                      width: 'full',
                                      gap: 20,
                                    }}
                                  >
                                    <Form.Item
                                      {...restOfField}
                                      config={{
                                        way: 'vertical',
                                        label: 'Langue',
                                        name: [name, 'lang'],
                                        rules: [{ required: true }],
                                      }}
                                    >
                                      <Select
                                        data={{
                                          items: formattedTranslateLang,
                                        }}
                                        config={{
                                          height: 'xlarge',
                                          width: 'medium',
                                        }}
                                      ></Select>
                                    </Form.Item>

                                    <Form.Item
                                      {...restOfField}
                                      config={{
                                        way: 'vertical',
                                        label: 'Nom du parking',
                                        name: [name, 'name'],
                                        rules: [{ required: true }],
                                      }}
                                    >
                                      <Input
                                        config={{
                                          height: 'xlarge',
                                          width: 'full',
                                          placeholder: 'Parking du Vélodrome',
                                        }}
                                      />
                                    </Form.Item>
                                    {/*
                                     
                                        <Form.Item
                                          {...restOfField}
                                          config={{
                                            way: 'vertical',
                                            label: 'Description du parking',
                                            name: [name, 'description'],
                                          }}
                                        >
                                          <Input
                                            config={{
                                              height: 'xlarge',
                                              width: 'full',
                                              placeholder: '',
                                            }}
                                          />
                                        </Form.Item> */}

                                    <div className="formListButtonContain">
                                      <div
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => remove(name)}
                                      >
                                        <Icon
                                          config={{
                                            type: 'faSquareMinusSolid',
                                            size: 'large',
                                            color: 'primary',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                );
                              },
                            )}
                            {fields.length < formattedTranslateLang.length && (
                              <Row config={{ horizontal: 'center' }}>
                                <Button
                                  handleEvent={{
                                    click: () => add(),
                                  }}
                                  config={{
                                    text: 'Ajouter un nom de parking dans une autre langue',
                                  }}
                                />
                              </Row>
                            )}
                          </Col>
                        )}
                      </Form.List>
                    </Col>

                    <Col config={{ gap: 20, width: 'full' }}>
                      <Write
                        data={{ item: 'Adresse du parking' }}
                        config={{
                          mode: 'title-small',
                        }}
                      ></Write>

                      <Row config={{ gap: 10, width: 'full' }}>
                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'streetNumber',
                            label: 'N° de voie',
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number', min: 1 },
                              height: 'xlarge',
                              width: 'xsmall',
                              placeholder: '7',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'streetNumberBis',
                            label: 'Suffixe',
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number' },
                              height: 'xlarge',
                              width: 'xsmall',
                              placeholder: '',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'streetType',
                            label: 'Type de voie',
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'large',
                              placeholder: 'Avenue',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'streetName',
                            label: 'Nom de la voie',
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: 'Montaigne',
                            }}
                          />
                        </Form.Item>
                      </Row>

                      <Row config={{ gap: 10, width: 'full' }}>
                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'specialPlace',
                            label: "Complément d'adresse",
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: 'La Tour Eiffel',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'postOfficeBoxNumber',
                            label: 'Boîte postale',
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '',
                            }}
                          />
                        </Form.Item>
                      </Row>

                      <Row config={{ gap: 10, width: 'full' }}>
                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'postalCode',
                            label: 'Code postal',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'small',
                              placeholder: '75008',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'addressLocality',
                            label: 'Ville',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '8ème arrondissement',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'addressSubRegion',
                            label: 'Département',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: 'Paris',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'addressCountry',
                            label: 'Pays (sur 2 caractères)',
                            rules: [{ required: true, max: 2, min: 2 }],
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: 'FR',
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>

                    <Col config={{ gap: 20, width: 'full' }}>
                      <Write
                        data={{ item: 'Coordonnées GPS du parking' }}
                        config={{
                          mode: 'title-small',
                        }}
                      ></Write>

                      <Row config={{ gap: 10, width: 'full' }}>
                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'latitude',
                            label: 'Latitude',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number' },
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '47,423557938',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'longitude',
                            label: 'Longitude',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number' },
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '9,3732261657',
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>

                    <Col config={{ gap: 20, width: 'full' }}>
                      <Write
                        data={{ item: 'Caractéristiques du parking' }}
                        config={{
                          mode: 'title-small',
                        }}
                      ></Write>

                      <Row config={{ gap: 10, width: 'full' }}>
                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'capacity',
                            label: 'Capacité',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number' },
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '180',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'occupancy',
                            label: 'Occupation',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              type: { name: 'number' },
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '54',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          config={{
                            way: 'vertical',
                            name: 'terminalId',
                            label:
                              'Identifiant appareil pour PrestoScan dans les FPS (unique pour le client, y.c. avec les PDA)',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              height: 'xlarge',
                              width: 'full',
                              placeholder: '998',
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Col>
                </div>
              </Form.Structure>
            </Form>
          )}
        </Zone.Area>
      </Zone>
    </>
  );
};
