import { Code, Col, WizardItemType, Write } from '@gimlite/watermelon';
import { CreateTariffWizardContext } from '../createTariff.wizard';

export const EditorStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateTariffWizardContext>) => {
  return (
    <Col config={{ gap: 20, width: 'full', height: 'full' }}>
      <Write
        data={{ item: 'Description du tarif' }}
        config={{
          mode: 'title-small',
        }}
      ></Write>

      <Code
        handleEvent={{
          input(value) {
            submit({ description: value });
          },
          isValid(value) {
            validate(value);
          },
        }}
        config={{ yMax: '100%', lang: 'json' }}
      ></Code>
    </Col>
  );
};
