import {
  Badge,
  Col,
  Color,
  Description,
  Space,
  Table,
  useMyUrl,
  useQuery,
  Widget,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Query, QuerySiteArgs } from '../../../../../client/graphql';
import { siteEventGql } from '../../gql/tab/event.gql';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsEvent = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  const { data, loading, error, refetch } = useQuery<
    { site: Query['site'] },
    QuerySiteArgs
  >(siteEventGql, {
    variables: {
      ...siteDetailsDefaultParams,
      ...getParamsUrl,
      siteId: siteId!,
    },
  });

  const site = useMemo(() => {
    if (!data) return null;

    const {
      site: { ...rest },
    } = data;

    return {
      ...rest,
    };
  }, [data]);

  const status = useMemo((): Record<
    number,
    {
      text: string;
      status: Extract<Color, 'error' | 'success' | 'warn' | 'basic'>;
    }
  > => {
    return {
      0: { text: 'Unregistered', status: 'error' },
      1: { text: 'Registered Home', status: 'success' },
      2: { text: 'Unregistered And Searching', status: 'error' },
      3: { text: 'Registration Denied', status: 'error' },
      4: { text: 'Registration Unknown', status: 'warn' },
      5: { text: 'Registered Roaming', status: 'success' },
    };
  }, []);

  const formattedAlarms = useCallback((state: string) => {
    switch (state) {
      case 'up':
        return <Badge config={{ text: 'UP', color: 'success' }} />;
      case 'down':
        return <Badge config={{ text: 'DOWN', color: 'error' }} />;
      default:
        return state;
    }
  }, []);

  const formatedRegistrationStatus = useCallback(
    (reg?: number | null) => {
      if (!reg) return <></>;

      return (
        <Badge
          config={{
            text: status?.[reg as keyof typeof status]?.text || '-',
            color: status?.[reg as keyof typeof status]?.status || 'default',
          }}
        />
      );
    },
    [status],
  );

  return (
    <Col>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Alarmes' }}
      >
        <Table<{
          _id: string;
          alarm: React.ReactNode;
          fragmentRef: React.ReactNode;
          startDate: string | null;
          stopDate: string | null;
          $expandable?: React.ReactNode;
        }>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                alarmsPage: value.page,
                alarmsLimit: value.limit,
              });
            },
          }}
          data={
            site?.alarms
              ? {
                  list: site?.alarms?.list?.map(
                    ({ alarm, startDate, extra, payload, stopDate, _id }) => {
                      return {
                        _id,
                        $expandable:
                          !!extra && extra.length ? (
                            <Description
                              data={extra?.map((data, index) => {
                                return {
                                  key: `extra-${index}`,
                                  label: `${index + 1}`,
                                  value: data,
                                };
                              })}
                            />
                          ) : undefined,
                        fragmentRef:
                          payload && !!payload.fragmentRef ? (
                            <Link
                              to={`/adm/fragmentsByRef/${payload?.fragmentRef}`}
                            >
                              {payload?.fragmentRef?.split(':')?.[1]}
                            </Link>
                          ) : (
                            <></>
                          ),
                        alarm: formattedAlarms(alarm),
                        startDate: startDate
                          ? DateTime.fromISO(startDate).toFormat(
                              'dd/MM/yy HH:mm:ss',
                            )
                          : null,
                        stopDate: stopDate
                          ? DateTime.fromISO(stopDate).toFormat(
                              'dd/MM/yy HH:mm:ss',
                            )
                          : null,
                      };
                    },
                  ),
                  paging: site?.alarms?.paging,
                }
              : undefined
          }
          config={{
            columns: [
              {
                title: 'Alarm',
                key: 'alarm',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'StartDate',
                key: 'startDate',
              },
              {
                title: 'StopDate',
                key: 'stopDate',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Modem' }}
      >
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                modemSessionsPage: value.page,
                modemSessionsLimit: value.limit,
              });
            },
          }}
          data={
            site?.modemSessions
              ? {
                  list: site?.modemSessions?.list.map((element) => {
                    const { _id, logDate, net, t } = element;

                    return {
                      _id,
                      logDate:
                        DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
                      technology: net?.rat ?? undefined,
                      registrationStatus: formatedRegistrationStatus(net?.reg),
                      operator:
                        net?.opn && net?.oper
                          ? `${net?.opn ?? '-'} / ${net?.oper ?? '-'}`
                          : undefined,
                      sim: net?.iccid,
                      channel:
                        net?.band && net?.chan
                          ? `${net?.band ?? '-'} / ${net?.chan ?? '-'}`
                          : undefined,
                      rsrp: net?.rsrp ? `${net?.rsrp} dBm` : undefined,
                      rsrq: net?.rsrq ? `${net?.rsrq} dB` : undefined,
                      startup: t?.conn ? `${t?.conn} s` : undefined,
                      transaction: t?.trx ? `${t?.trx} s` : undefined,
                      suspending: t?.susp ? `${t?.susp} s` : undefined,
                      quality: net?.rsrp ? (
                        net?.rsrp >= 0 ? (
                          <Badge
                            config={{ color: 'disabled', text: 'Unknown' }}
                          />
                        ) : net?.rsrp <= -100 ? (
                          <Badge config={{ color: 'error', text: 'Poor' }} />
                        ) : net?.rsrp <= -90 ? (
                          <Badge config={{ color: 'warn', text: 'Medium' }} />
                        ) : net?.rsrp <= -80 ? (
                          <Badge config={{ color: 'success', text: 'Good' }} />
                        ) : (
                          <Badge
                            config={{ color: 'success', text: 'Excellent' }}
                          />
                        )
                      ) : undefined,
                      connRecv: element.conn?.recv
                        ? `${element.conn?.recv}`
                        : '',
                      connSent: element.conn?.sent
                        ? `${element.conn?.sent}`
                        : '',
                      netCell: net?.cell ? `${net?.cell}` : '',
                      netLac: net?.lac ? `${net?.lac}` : '',
                    };
                  }),
                  paging: site?.modemSessions?.paging,
                }
              : undefined
          }
          config={{
            columns: [
              {
                title: 'Datetime',
                key: 'logDate',
              },
              {
                title: 'Technology',
                key: 'technology',
              },
              {
                title: 'Operator',
                key: 'operator',
              },
              {
                title: 'SIM',
                key: 'sim',
              },
              {
                title: 'Channel',
                key: 'channel',
              },
              {
                title: 'RSRP',
                key: 'rsrp',
              },
              {
                title: 'RSRQ',
                key: 'rsrq',
              },
              {
                title: 'Startup',
                key: 'startup',
              },
              {
                title: 'Transaction',
                key: 'transaction',
              },
              {
                title: 'Suspending',
                key: 'suspending',
              },
              {
                title: 'Registration',
                key: 'registrationStatus',
              },
              {
                title: 'Quality',
                key: 'quality',
              },
              {
                title: 'Bytes Received',
                key: 'connRecv',
              },
              {
                title: 'Bytes Sent',
                key: 'connSent',
              },
              {
                title: 'E-UTRAN Cell Identity',
                key: 'netCell',
              },
              {
                title: 'Local Area Code',
                key: 'netLac',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Service Card Event' }}
      >
        <Table<{
          _id: string;
          logDate: string | null;
          UID: React.ReactNode;
          Status: React.ReactNode | null;
          Details: string | null;
          Type: string | null;
          name: string | null;
        }>
          handleEvent={{
            paging: (value: any) => {
              setParamsUrl({
                ...getParamsUrl,
                serviceCardEventPage: value.page,
                serviceCardEventLimit: value.limit,
              });
            },
          }}
          data={
            site?.serviceCardEvents
              ? {
                  list: site?.serviceCardEvents?.list.map(
                    ({ logDate, UID, Status, comment, ...other }) => {
                      return {
                        ...other,
                        UID: <pre>{UID}</pre>,
                        Status: Status ? (
                          'ok' === Status ? (
                            <Badge
                              config={{ color: 'success', text: Status }}
                            />
                          ) : (
                            <Badge config={{ color: 'warn', text: Status }} />
                          )
                        ) : null,
                        logDate:
                          DateTime.fromISO(logDate).toFormat(
                            'dd/MM/yy HH:mm:ss',
                          ),
                        name: comment || null,
                      };
                    },
                  ),
                  paging: site?.serviceCardEvents?.paging,
                }
              : undefined
          }
          config={{
            columns: [
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'Name',
                key: 'name',
              },
              {
                title: 'Card UID',
                key: 'UID',
              },
              {
                title: 'Type',
                key: 'Type',
              },
              {
                title: 'Status',
                key: 'Status',
              },
              {
                title: 'Details',
                key: 'Details',
              },
            ],
          }}
        />
      </Widget>
    </Col>
  );
};
