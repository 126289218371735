import { Table, useLazyQuery, Widget } from "@gimlite/watermelon"
import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { AutoTestsPaging, QueryAutotestsArgs } from "../../../../../client/graphql"
import { autotestsGql } from "../../gql/tab/autotests.gql"

type RenderedAutotest = {
    _id: string
    device: string
    result: string
    $expandable: any
}

export const AutotestsCategory = () => {

    const { siteId } = useParams()

    const [fetchAutotests, { data, loading, error, refetch }] = useLazyQuery<
        { autotests: AutoTestsPaging },
        QueryAutotestsArgs
    >(autotestsGql, {
        notification: {
            error: 'Erreur lors de la récupération des autotests',
        },
        variables: { siteId },
    })

    useEffect(() => {
        if (siteId) {
            console.log('siteId', siteId)
            fetchAutotests({ variables: { siteId: siteId } }).then((d) => {
                console.log('d', d)
            })
        }
    }, [siteId])

    const autotests = useMemo(() => {
        return data?.autotests.list.map(({ _id, device, infos, result }) => {
            return {
                _id,
                device,
                result,
                $expandable: (<ul>
                    {infos.map((info, index) => (
                        <li key={index}>{info}</li>
                    ))}
                </ul>),
            }
        })
    }, [data])

    return (
        <Widget
            config={{ title: 'Autotests' }}
            state={{ loading, error, refetch }}
        >
            <Table<RenderedAutotest> 
                data={{
                    list: autotests || [],
                    paging: data?.autotests.paging,
                }}
                config={{
                    columns: [
                        { key: 'device', title: 'Device' },
                        { key: 'result', title: 'Result' },
                    ],
                    actions: {
                        read: true
                    }
                }}
            />
        </Widget>
    )

}