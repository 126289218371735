export const upload = (
  files: FileList,
  format: 'string' | 'array-buffer' | 'binary' | 'data-url',
): Promise<string[] | Error | Array<unknown>> => {
  return new Promise((resolve, reject) => {
    if (files.length > 0) {
      try {
        const all = Promise.all(
          Array.from(files).map((file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();

              reader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(e.target?.result as string);
              };

              reader.onerror = (error) => {
                reject(error);
              };

              switch (format) {
                case 'string':
                  reader.readAsText(file);
                  break;

                case 'array-buffer':
                  reader.readAsArrayBuffer(file);
                  break;

                case 'binary':
                  reader.readAsBinaryString(file);
                  break;

                case 'data-url':
                  reader.readAsDataURL(file);
                  break;
              }
            });
          }),
        );

        all.then(resolve).catch(reject);
      } catch (error) {
        reject(new Error(`An error occurred in the download list`));
      }
    } else {
      reject(new Error('No file'));
    }
  });
};
