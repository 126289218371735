import { gql } from '@apollo/client'

export const terminalActionGql = gql`
    query terminalAction(
        $id: String!
    ) {
        terminalAction(
            id: $id
        ) {
            siteId
            terminalId
            type
            status
            start
            deadline
            params
            result
        }
    }
`