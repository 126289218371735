import { Table, useQuery, Widget, Zone, useMyUrl } from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryClientArgs } from '../../../../../client/graphql';
import { clientGql } from '../../gql/client.gql';

export const ClientsDetailsFinance = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { getParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql, {
    variables: { ...getParamsUrl, clientId: clientId! },
  });

  const client = useMemo(() => {
    if (!data) return null;

    return data.client;
  }, [data]);

  return (
    <Zone
      config={{
        zones: [['table']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'table' }}>
        <Widget
          state={{ loading, error, refetch }}
          config={{ title: 'Liste des paiements' }}
        >
          <Table<{
            _id: string;
            consumer?: string | null;
            amount?: number | null;
            currency?: string | null;
            type?: string | null;
            paymentDate?: string | null;
            segment?: string | null;
          }>
            data={
              client?.paymentRecords
                ? {
                    list: client.paymentRecords?.list?.map(
                      ({ _id, paymentDate, ...rest }) => ({
                        _id: _id!,
                        paymentDate: paymentDate
                          ? DateTime.fromISO(paymentDate).toFormat(
                              'dd/MM/yy HH:mm:ss',
                            )
                          : 'null',
                        ...rest,
                      }),
                    ),
                    paging: client.paymentRecords.paging,
                  }
                : undefined
            }
            config={{
              columns: [
                {
                  title: 'consumer',
                  key: 'consumer',
                },
                {
                  title: 'amount',
                  key: 'amount',
                },
                {
                  title: 'currency',
                  key: 'currency',
                },
                {
                  title: 'type',
                  key: 'type',
                },
                {
                  title: 'paymentDate',
                  key: 'paymentDate',
                },
                {
                  title: 'segment',
                  key: 'segment',
                },
              ],
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
