import { gql } from '@apollo/client';

export const upsertLayersGql = gql`
  mutation upsertLayers($input: UpsertLayersDto!) {
    upsertLayers(input: $input) {
      layers {
        _id
      }
    }
  }
`;
