import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Loading } from '../../loading/loading.component';
import { MapsContext } from '../maps.component';

export const MapsLoaderModule = observer(() => {
  const {
    load,
    modules: { loader },
  } = useContext(MapsContext);

  return !load || (loader && loader?.loading) ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        inset: '0 0 0 0',
        zIndex: 1000,
        backgroundColor: 'var(--color-elevate)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading />
    </div>
  ) : null;
});
