import { gql } from '@apollo/client';
import { CsvParkingRightsResponseDto } from '../../../../client/graphql';

export type CSVParkingRightsResponse = {
  downloadURL: CsvParkingRightsResponseDto['downloadURL'];
};

export const csvParkingRightsGql = gql`
  query csvParkingRights($clientId: String) {
    csvParkingRights(clientId: $clientId) {
      downloadURL
    }
  }
`;
