import Color from 'color';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { setCSS } from '../functions/css.function';
import { RootStoreMobX } from './root.store';
import { useRootStore } from '../hook/useRootStore.hook';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { logWithState } from '../functions/console.function';

export type ThemeStoreContextType = {
  theme: 'dark' | 'light';
  defaultTheme: ThemeStoreContextType['theme'];
  color: {
    dark: string;
    light: string;
  };
};

const colorsV1 = {
  light: {
    background: '#F5F5F5',
    layout: '#ffffff',
    card: '#ffffff',
    border: '#dcdcdc',
    elevate: 'rgba(10, 10, 10, 0.5)',
    intermediate: '#dddddd',
    disabled: '#a0a0a0',
    label: '#848484',
    text: '#333333',
    yin: '#ffffff',
    yang: '#333333',
    shadow: 'rgba(0, 0, 0, 0.15)',
    error: '#ff4d4f',
    warn: '#f48c06',
    info: '#009fb7',
    yell: '#cfba00',
    success: '#51cf84',
  },
  dark: {
    background: '#09090B',
    layout: '#18181B',
    card: '#09090B',
    border: '#4d4d4d',
    intermediate: '#383838',
    elevate: 'rgba(10, 10, 10, 0.5)',
    disabled: '#989898',
    label: '#DEDEDE',
    text: '#e8e8e8',
    yin: '#333333',
    yang: '#e8e8e8',
    shadow: 'rgba(0, 0, 0, 0.2)',
    error: '#ff4d4f',
    warn: '#c46b03',
    info: '#00798f',
    yell: '#a38a00',
    success: '#3a9e5c',
  },
};

const colorsV2 = {
  light: {
    deep_indigo: '#232653',
    deep_indigo_70: '#656787',
    deep_indigo_50: '#9192A2',
    deep_indigo_30: '#BDBECB',
    deep_indigo_10: '#E9E9EE',
    deep_indigo_4: '#F6F6F8',
    soft_teal: '#20C997',
    soft_teal_70: '#63D9B6',
    soft_teal_50: '#8FE4CB',
    soft_teal_30: '#BCEFE0',
    soft_teal_10: '#E9FAF5',
    black_90: '#232323',
    black_70: '#3F3F3F',
    black_50: '#7F7F7F',
    black_15: '#D8D8D8',
    black_4: '#F4F4F4',
    black_2: '#FAFAFA',
    alert_success: '#28A745',
    alert_success_50: '#93D3A2',
    alert_warning: '#FD7E14',
    alert_warning_50: '#FEBE89',
    alert_advisory: '#FFBF00',
    alert_advisory_50: '#FFDF80',
    alert_error: '#DC3545',
    alert_error_50: '#ED9AA2',
    alert_info: '#17A2B8',
    alert_info_50: '#8BD1DB',
  },
  dark: {
    deep_indigo: '#232653',
    deep_indigo_70: '#656787',
    deep_indigo_50: '#9192A2',
    deep_indigo_30: '#BDBECB',
    deep_indigo_10: '#E9E9EE',
    deep_indigo_4: '#F6F6F8',
    soft_teal: '#20C997',
    soft_teal_70: '#63D9B6',
    soft_teal_50: '#8FE4CB',
    soft_teal_30: '#BCEFE0',
    soft_teal_10: '#E9FAF5',
    black_90: '#232323',
    black_70: '#3F3F3F',
    black_50: '#7F7F7F',
    black_15: '#D8D8D8',
    black_4: '#F4F4F4',
    black_2: '#FAFAFA',
    alert_success: '#28A745',
    alert_success_50: '#93D3A2',
    alert_warning: '#FD7E14',
    alert_warning_50: '#FEBE89',
    alert_advisory: '#FFBF00',
    alert_advisory_50: '#FFDF80',
    alert_error: '#DC3545',
    alert_error_50: '#ED9AA2',
    alert_info: '#17A2B8',
    alert_info_50: '#8BD1DB',
  },
};

const colors = Object.freeze({
  theme: {
    light: {
      ...colorsV1.light,
      ...colorsV2.light,
    },
    dark: {
      ...colorsV1.dark,
      ...colorsV2.dark,
    },
  },
  base: {
    white: '#ffffff',
    black: '#333333',
  },
} as const);

export class ThemeStoreMobX {
  rootStore: RootStoreMobX;
  @observable public theme: ThemeStoreContextType['theme'] = 'light';
  @observable public registryColors = colors;
  @observable private colorsTheme = colors.theme[this.theme];
  @observable private colorsDynamic?: ThemeStoreContextType['color'];
  @observable private colorsBase = colors.base;
  @observable public load: boolean = false;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init({
    color,
    defaultTheme,
  }: Pick<ThemeStoreContextType, 'color' | 'defaultTheme'>) {
    this.load = false;
    this.theme =
      (localStorage.getItem('theme') as
        | ThemeStoreContextType['theme']
        | null) || defaultTheme;
    this.colorsDynamic = color;
    this.setTheme(this.theme);
    this.load = true;
  }

  @action public getTheme() {
    return localStorage.getItem('theme') as
      | ThemeStoreContextType['theme']
      | null;
  }

  @action public setTheme(defaultTheme: ThemeStoreContextType['theme']) {
    this.theme = defaultTheme;
    this.colorsTheme = colors.theme[this.theme];
    localStorage.setItem('theme', this.theme);
    this.setColorsTheme(this.theme);
    this.setColorsDynamic(this.theme);
    this.setColorsBase(this.theme);
    this.setColorsChart(this.theme);
  }

  @action private setColorsTheme(theme: ThemeStoreContextType['theme']) {
    Object.entries(this.colorsTheme).forEach(([key, value]) =>
      setCSS(`--color-${key}`, value as string),
    );

    const { error, warn, info, yell, success } = this.colorsTheme;
    Object.entries({ error, warn, info, yell, success }).forEach(
      ([key, value]) => {
        for (let i = 10; i <= 100; i += 10) {
          setCSS(
            `--color-${key}-alpha-${i}`,
            `${new Color(value).alpha(i / 100)}`,
          );
        }
      },
    );
  }

  @action private setColorsChart(theme: ThemeStoreContextType['theme']) {
    // Chart.defaults.borderColor = this.colorsTheme.border;
  }

  @action private setColorsDynamic(theme: ThemeStoreContextType['theme']) {
    if (this.colorsDynamic) {
      const colorPrimaryOver = !new Color(this.colorsDynamic[theme]).isDark()
        ? new Color(this.colorsBase.black).alpha(1).string()
        : new Color(this.colorsBase.white).alpha(1).string();

      setCSS(`--color-primary`, this.colorsDynamic[theme]);
      setCSS(`--color-primary-over`, colorPrimaryOver);

      for (let i = 5; i <= 100; i += 5) {
        setCSS(
          `--color-primary-alpha-${i}`,
          `${new Color(this.colorsDynamic[theme]).alpha(i / 100)}`,
        );
      }

      setCSS(
        '--color-selected',
        `${new Color(this.colorsDynamic[theme]).alpha(0.2)}`,
      );

      setCSS(
        '--color-over',
        `${new Color(this.colorsDynamic[theme]).alpha(0.1)}`,
      );
    }
  }

  @action private setColorsBase(theme: ThemeStoreContextType['theme']) {
    const colorInfoOver = new Color(this.colorsTheme.info).isDark()
      ? new Color(colors.base.white).alpha(1).toString()
      : new Color(colors.base.black).alpha(1).toString();

    setCSS(`--color-white`, colors.base.white);
    setCSS(`--color-black`, colors.base.black);
    setCSS(`--color-info-over`, colorInfoOver);
  }
}

export const ConfigTheme = observer(
  ({
    children,
    color,
    defaultTheme,
  }: { children: React.ReactNode } & Pick<
    ThemeStoreContextType,
    'color' | 'defaultTheme'
  >) => {
    const { ThemeStore } = useRootStore();

    useEffect(() => {
      if (!ThemeStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigTheme init' });
        ThemeStore.init({ color, defaultTheme });
      }

      if (ThemeStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigTheme load' });
      }
    }, [ThemeStore.load]);

    return ThemeStore.load ? children : null;
  },
);
