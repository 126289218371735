import {
  Button,
  Description,
  FilterExpanded,
  Page,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
  useRootStore,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Query, QueryUpsArgs } from '../../../../client/graphql';
import { upsGql } from '../gql/ups.gql';

export const UpsDetails = () => {
  const navigate = useNavigate();
  const { upsId } = useParams<{ upsId: string }>();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { ups: Query['ups'] },
    QueryUpsArgs
  >(upsGql, {
    variables: { ...getParamsUrl, upsId: upsId! },
  });

  const ups = useMemo(() => {
    if (!data) return null;

    return data.ups;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['description'], ['sites']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'description' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: "Détails de l'UPS" }}
          >
            {ups && (
              <Description
                data={[
                  { key: 'code', label: 'Code', value: ups?.code },
                  { key: 'client', label: 'Client', value: ups?.client?.slug },
                ]}
              />
            )}
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'sites' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: 'Liste des sites' }}
          >
            <Zone
              config={{
                zones: [['filter'], ['table']],
                rows: ['min-content', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'filter' }}>
                <FilterExpanded
                  data={{ value: getParamsUrl }}
                  handleEvent={{
                    submit: (data: any) => {
                      setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                    },
                  }}
                >
                  <FilterExpanded.Fields></FilterExpanded.Fields>
                  <FilterExpanded.Actions>
                    <Button
                      config={{
                        text: 'Ajouter un site',
                        type: { value: 'button' },
                      }}
                      handleEvent={{
                        click: () => {
                          WizardStore.select({
                            id: 'createSite',
                            store: {
                              clientId: ups?.client?._id,
                              upsId,
                            },
                          });
                        },
                      }}
                    />
                  </FilterExpanded.Actions>
                </FilterExpanded>
              </Zone.Area>
              <Zone.Area config={{ area: 'table' }}>
                <Table<{
                  _id: string;
                  name?: string | null;
                }>
                  handleEvent={{
                    paging: (value) => {
                      setParamsUrl({
                        ...getParamsUrl,
                        sitesPage: value.page,
                        sitesLimit: value.limit,
                      });
                    },
                    read: (value) => {
                      navigate(`/onstreet/sites/${value._id}`);
                    },
                  }}
                  data={
                    ups?.sites
                      ? {
                          list: ups?.sites?.list.map((site) => ({
                            _id: site._id!,
                            name: site.name,
                          })),
                          paging: ups?.sites?.paging,
                        }
                      : undefined
                  }
                  config={{
                    actions: { read: true },
                    columns: [
                      {
                        title: 'Name',
                        key: 'name',
                      },
                    ],
                  }}
                />
              </Zone.Area>
            </Zone>
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
