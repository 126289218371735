import { Popconfirm as PopconfirmAnt, Popover as PopoverAnt } from 'antd';
import React, { createContext, useState } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import type { Placement2Axis, Size, Trigger } from '../../types/component.type';
import './popover.component.scss';

export declare namespace PopoverType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    data: React.ReactNode | string;
    config?: {
      placement?: Extract<
        Placement2Axis,
        | 'topLeft'
        | 'top'
        | 'topRight'
        | 'leftTop'
        | 'left'
        | 'leftBottom'
        | 'rightTop'
        | 'right'
        | 'rightBottom'
        | 'bottomLeft'
        | 'bottom'
        | 'bottomRight'
      >;
      trigger?: Extract<Trigger, 'click' | 'hover'>;
      minWidth?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
      >;
      maxWidth?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
      >;
      padding?: boolean;
    };
  };
}

export const Popover = ({
  className = '',
  children,
  data,
  config: {
    placement = 'bottom',
    trigger = 'click',
    minWidth = 'initial',
    maxWidth = 'initial',
    padding = true,
  } = {},
}: PopoverType.Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover.Context.Provider value={{ close: () => setOpen(() => false) }}>
      <PopoverAnt
        onOpenChange={(value) => {
          setOpen(value);
        }}
        open={open}
        rootClassName={`
          popover 
          popover--minWidth--${minWidth}
          popover--maxWidth--${maxWidth}
          ${!padding ? 'popover--nopadding' : ''}
        `}
        className={`${className}`}
        content={data}
        trigger={trigger}
        placement={placement}
        destroyTooltipOnHide={true}
      >
        <div
          onClick={(e) => {
            setOpen(true);
          }}
          className="popover__button"
        >
          {children}
        </div>
      </PopoverAnt>
    </Popover.Context.Provider>
  );
};

Popover.Context = createContext<{
  close: () => void;
}>({ close: () => {} });

export declare namespace PopoverConfirmType {
  type Props = {
    handleEvent?: {
      confirm?: () => void;
      cancel?: () => void;
    };
    className?: string;
    children: React.ReactNode;
    config: {
      title: string;
      description: string;
      width?: Extract<Size, 'initial' | 'full'>;
    };
  };
}

Popover.Confirm = ({
  handleEvent: { confirm, cancel } = {},
  children,
  className = '',
  config: { title, description, width = 'initial' },
}: PopoverConfirmType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <PopconfirmAnt
      className={`popoverconfirm popoverconfirm--width--${width} ${className}`}
      onConfirm={(e) => {
        e?.stopPropagation();
        confirm?.();
      }}
      onCancel={(e) => {
        e?.stopPropagation();
        cancel?.();
      }}
      title={title}
      description={description}
      okText={t('watermelon-confirm')}
      cancelText={t('watermelon-cancel')}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </PopconfirmAnt>
  );
};
