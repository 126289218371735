import {
  DatePicker,
  FilterExpanded,
  Form,
  Icon,
  NavInline,
  Page,
  Widget,
  lastWeek,
  now,
  useMyUrl,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

export const siteDetailsDefaultParams = {
  startDate: lastWeek('DATE').toISOString(),
  stopDate: now('DATE').toISOString(),
} as const;

export const siteDetailsDefaultParamsEncode = `startDate=${encodeURIComponent(
  new Date(siteDetailsDefaultParams.startDate).toISOString(),
)}&stopDate=${encodeURIComponent(
  new Date(siteDetailsDefaultParams.stopDate).toISOString(),
)}`;

export const SitesDetailsFilter = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  return (
    <Widget config={{ title: 'Filtres' }}>
      <FilterExpanded
        data={{
          value: {
            time:
              getParamsUrl?.startDate && getParamsUrl?.stopDate
                ? [getParamsUrl.startDate, getParamsUrl.stopDate]
                : Object.values(siteDetailsDefaultParams),
          },
        }}
        handleEvent={{
          change: ({ time }) => {
            setParamsUrl({ startDate: time[0], stopDate: time[1] });
          },
          submit: ({ time }: any) => {},
        }}
      >
        <FilterExpanded.Fields>
          <Form.Item
            config={{
              name: 'time',
              label: 'Plage de temps',
            }}
          >
            <DatePicker config={{ mode: 'range', format: 'date' }} />
          </Form.Item>
        </FilterExpanded.Fields>
        <FilterExpanded.Actions>
          {/* <Button config={{ text: 'Rechercher', type: {value: 'submit'} }} /> */}
        </FilterExpanded.Actions>
      </FilterExpanded>
    </Widget>
  );
};

export const SitesDetails = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const { getParamsUrlString } = useMyUrl();

  const currentCategory = useMemo(() => {
    const pathnameDecompose = window.location.pathname.split('/');
    return pathnameDecompose[pathnameDecompose.length - 1];
  }, [window.location.pathname]);

  return (
    <Page config={{ overflow: true }}>
      <NavInline
        config={{
          selected: currentCategory,
        }}
        data={[
          {
            label: 'Global',
            key: 'global',
            icon: <Icon config={{ type: 'faGlobeSolid' }} />,
          },
          {
            label: 'Finance',
            key: 'finance',
            icon: <Icon config={{ type: 'faSackDollarSolid' }} />,
          },
          {
            label: 'Énergie',
            key: 'energy',
            icon: <Icon config={{ type: 'faBoltSolid' }} />,
          },
          {
            label: 'Évenement',
            key: 'event',
            icon: <Icon config={{ type: 'faBellSolid' }} />,
          },
          {
            label: 'Fragments',
            key: 'fragment',
            icon: <Icon config={{ type: 'faBaseballBatBallSolid' }} />,
          },
          {
            label: 'Feedbacks',
            key: 'feedback',
            icon: <Icon config={{ type: 'faCommentMedicalSolid' }} />,
          },
          {
            label: 'Autotests',
            key: 'autotests',
            icon: <Icon config={{ type: 'faToolsSolid' }} />,
          }
        ]}
        handleEvent={{
          navigation: (value: string) => {
            navigate(
              `/onstreet/sites/${siteId}/${value}?${
                getParamsUrlString || siteDetailsDefaultParamsEncode
              }`,
            );
          },
        }}
      />

      <Outlet />
    </Page>
  );
};
