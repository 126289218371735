import { Button, Code, EditorSyntaxError, Page, Table, useLazyQuery, useMutation, Widget, Write, Zone } from "@gimlite/watermelon"
import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Mutation, MutationCreateTerminalActionArgs, MutationDeleteTerminalActionArgs, MutationUpdateTerminalActionArgs, Query, QueryTerminalActionArgs, TerminalActionEntity } from "../../../../client/graphql"
import terminalActionSchema from '../../../common/schemas/terminal-action.json'
import { createTerminalActionGql } from "../gql/create-terminal-action.gql"
import { deleteTerminalActionGql } from "../gql/delete-terminal-action.gql"
import { terminalActionGql } from "../gql/terminal-action.gql"
import { updateTerminalActionGql } from "../gql/update-terminal-action.gql"


function renderTerminalAction(action?: TerminalActionEntity) {
    if (!action) {
        return null
    }

    const { siteId, terminalId, status, type, start, deadline, params, result } = action

    const rendered = {
        siteId,
        terminalId,
        status,
        type,
        start,
        result
    } as TerminalActionEntity

    if (deadline) {
        rendered.deadline = deadline
    }

    if (params) {
        rendered.params = params
    }

    return rendered
}

export type TerminalActionProps = {
    newAction?: boolean
}

export const TerminalAction = ({ 
    newAction = false
}: TerminalActionProps) => {

    const { terminalActionId } = useParams()
    const navigate = useNavigate()

    const [fetch, { data, loading, error, refetch }] = useLazyQuery<
        { terminalAction: Query['terminalAction'] },
        QueryTerminalActionArgs
    >(terminalActionGql)

    const [deleteAction, { error: deleteError }] = useMutation<
        Mutation['deleteTerminalAction'],
        MutationDeleteTerminalActionArgs
    >(deleteTerminalActionGql, {
        notification: {
            error: 'Failed to delete terminal'
        }
    })

    const [createAction, { error: createError }] = useMutation<
        Mutation['createTerminalAction'],
        MutationCreateTerminalActionArgs
    >(createTerminalActionGql, {
        notification: {
            error: 'Failed to create terminal action'
        }
    })

    const [updateAction, { error: updateError }] = useMutation<
        Mutation['updateTerminalAction'],
        MutationUpdateTerminalActionArgs
    >(updateTerminalActionGql, {
        notification: {
            success: 'Terminal action updated successfully',
            error: 'Failed to update terminal action'
        }
    })

    const [errors, setErrors] = useState<EditorSyntaxError[]>([])

    const [currentAction, setCurrentAction] = useState<string | null>(null)

    useEffect(() => {
        if (terminalActionId) {
            fetch({ variables: { id: terminalActionId } })
        }
    }, [terminalActionId])

    const action = useMemo(() => {
        setCurrentAction(JSON.stringify(data?.terminalAction, null, 4))

        if (newAction) {
            return {
                status: 'pending',
                type: 'sys/reboot',
                siteId: '',
                terminalId: '',
                start: DateTime.now().toISO(),
                deadline: DateTime.now().plus({ days: 7 }).toISO(),
                params: { }
            }
        }

        return renderTerminalAction(data?.terminalAction)
    }, [data, terminalActionId])

    const editable = useMemo(() => {
        return data?.terminalAction?.status === 'pending' || newAction
    }, [data])

    const handleSave = () => {
        if (newAction) {
            createAction({ variables: { terminalAction: JSON.parse(currentAction!) } }).then(() => {
                if (!createError) {
                    navigate('/adm/terminal-actions')
                }
            })
        }

        if (terminalActionId) {
            updateAction({ variables: { id: terminalActionId, ...JSON.parse(currentAction!) } }).then(() => {
                if (!updateError) {
                    refetch()
                }
            })
        }
    }

    const handleDelete = () => {
        if (!newAction && terminalActionId) {
            deleteAction({ variables: { id: terminalActionId } }).then(() => {
                if (!deleteError) {
                    navigate('/adm/terminal-actions')
                }
            })
        }
    }

    return (
        <Page>
            <Zone
                config={{
                    zones: editable ? [['code', 'side']] : [['code']],
                    rows: ['1fr'],
                    columns: editable ? ['5fr', '3fr'] : ['1fr'],
                }}
            >
                <Zone.Area config={{ area: 'code' }}>
                    <Widget
                        config={{ title: 'Terminal action editor' }}
                        state={{ loading, error }}
                    >
                        <Code
                            data={{ defaultValue: JSON.stringify(action, null, 4) }}
                            config={{
                                yMax: '100%',
                                lang: 'json',
                                readOnly: !editable,
                                jsonSchemaUri: 'https://www.iemgroup.com/schemas/terminal-action.json',
                                jsonSchema: terminalActionSchema
                            }}
                            handleEvent={{ 
                                handleErrors: errors => {
                                    setErrors(errors)
                                },
                                input: value => setCurrentAction(value)
                            }}
                        />
                    </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'side' }}>
                    {editable && (
                        <Zone.Area config={{ area: 'actions' }}>
                            <Zone
                                config={{
                                    zones: [['actions'], ['output']],
                                    rows: ['min-content', '1fr'],
                                    columns: ['1fr']
                                }}
                            >
                                <Zone.Area>
                                    <Widget config={{ subtitle: 'actions' }}>
                                        <Zone
                                            config={{
                                                zones: [['save', 'delete']],
                                                rows: ['1fr'],
                                                columns: ['1fr', '1fr']
                                            }}
                                        >
                                            <Zone.Area config={{ area: 'save' }}>
                                                <Button
                                                    config={{ text: 'Save', disabled: !editable || errors.length > 0 }}
                                                    handleEvent={{ click: handleSave }}
                                                />
                                            </Zone.Area>

                                            <Zone.Area config={{ area: 'delete' }}>
                                                <Button
                                                    config={{ text: 'Delete', color: 'error', disabled: !editable }}
                                                    handleEvent={{ click: handleDelete }}
                                                />
                                            </Zone.Area>
                                        </Zone>
                                    </Widget>
                                </Zone.Area>

                                <Zone.Area config={{ area: 'output' }}>

                                    <Widget config={{ subtitle: 'output' }}>
                                        {errors.length > 0 && (
                                            <Table<EditorSyntaxError>
                                                data={{
                                                    list: errors,
                                                    paging: { count: errors.length, current: 1, limit: errors.length }
                                                }}
                                                config={{
                                                    columns: [
                                                        { key: 'startLineNumber', title: 'Line' },
                                                        { key: 'startColumn', title: 'Column' },
                                                        { key: 'message', title: 'Message' },
                                                    ]
                                                }}
                                            />
                                        )}

                                        {errors.length === 0 && <Write data={{ item: 'No syntax errors found.' }} />}
                                    </Widget>
                                </Zone.Area>
                            </Zone>
                        </Zone.Area>
                    )}

                    {!editable && <></>}
                </Zone.Area>
            </Zone>
        </Page>
    )

}