import {
  Badge,
  Blurb,
  Button,
  Col,
  Description,
  Icon,
  Image,
  ListFlatInfo,
  Page,
  Row,
  Space,
  Statistic,
  Table,
  useMyUrl,
  useQuery,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryOperatorArgs } from '../../../../client/graphql';
import { operatorGql } from '../gql/operator.gql';

export const OperatorsDetails = () => {
  const { operatorId } = useParams<{ operatorId: string }>();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { operator: Query['operator'] },
    QueryOperatorArgs
  >(operatorGql, {
    variables: { ...getParamsUrl, operatorId: operatorId! },
  });

  const operator = useMemo(() => {
    if (!data) return null;

    return data.operator;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['details']],
          rows: ['min-content'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Row>
            <Col>
              <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Logo principal' }}
              >
                {operator?.display?.logoMain && (
                  <Image
                    data={{ src: operator?.display?.logoMain }}
                    config={{ preview: true, size: 'medium' }}
                  />
                )}
                {!operator?.display?.logoMain && (
                  <Badge
                    config={{
                      color: 'disabled',
                      text: 'Non spécifié',
                    }}
                  />
                )}
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Logo secondaire' }}
              >
                {operator?.display?.logoSecondary && (
                  <Image
                    data={{ src: operator?.display?.logoSecondary }}
                    config={{ preview: true, size: 'small' }}
                  />
                )}
                {!operator?.display?.logoSecondary && (
                  <Badge
                    config={{
                      color: 'disabled',
                      text: 'Non spécifié',
                    }}
                  />
                )}
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Nom' }}
              >
                <ListFlatInfo<'icon' | 'blurb'>
                  config={{
                    pagination: 'none',
                    columns: [
                      { key: 'icon', label: '', size: 'min-content' },
                      { key: 'blurb', label: '', size: '1fr' },
                    ],
                  }}
                  data={{
                    list: operator?.translation?.map(
                      ({ name, lang }: { name: string; lang: string }) => {
                        return {
                          _id: name,
                          items: {
                            icon: (
                              <Icon
                                config={{
                                  type: 'faLanguageSolid',
                                  color: 'primary',
                                }}
                              ></Icon>
                            ),
                            blurb: (
                              <Blurb
                                data={{
                                  title: name,
                                  description: lang.toUpperCase(),
                                }}
                              ></Blurb>
                            ),
                          },
                        };
                      },
                    ),
                  }}
                ></ListFlatInfo>
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Codes' }}
              >
                <Description
                  data={
                    operator
                      ? [
                          { key: 'code', label: 'Code', value: operator?.code },
                          {
                            key: 'op-code',
                            label: "Code d'opérateur",
                            value: operator?.operatorCode,
                          },
                        ]
                      : []
                  }
                ></Description>
              </Widget>
              <Space config={{ count: 2 }} />

              <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Contact' }}
              >
                <ListFlatInfo<'icon' | 'blurb'>
                  config={{
                    pagination: 'none',
                    columns: [
                      { key: 'icon', label: '', size: 'min-content' },
                      { key: 'blurb', label: '', size: '1fr' },
                    ],
                  }}
                  data={{
                    list: [
                      {
                        title: operator?.email || '-',
                        type: 'email',
                      },
                      {
                        title: operator?.phone || '-',
                        type: 'phone',
                      },
                    ].map(({ title, type }) => {
                      return {
                        _id: title,
                        items: {
                          icon: (
                            <Icon
                              config={{
                                type:
                                  type === 'email'
                                    ? 'faAtSolid'
                                    : 'faPhoneSolid',
                                color: 'primary',
                              }}
                            ></Icon>
                          ),
                          blurb: <Blurb data={{ title: title }}></Blurb>,
                        },
                      };
                    }),
                  }}
                ></ListFlatInfo>
              </Widget>
            </Col>
            <Space config={{ way: 'horizontal', count: 2 }} />
            <Col
              config={{
                flex: 2,
              }}
            >
              {operator && (
                <>
                  <Widget
                    state={{ loading, error, refetch }}
                    config={{ title: 'Décompte des contrats' }}
                  >
                    <Statistic
                      data={{ value: operator.operatorContractCount || 0 }}
                      config={{
                        precision: 0,
                      }}
                    />
                  </Widget>
                  <Space config={{ count: 2 }} />
                </>
              )}

              {operator?.contracts && (
                <>
                  <Widget
                    state={{ loading, error, refetch }}
                    config={{ title: 'Liste des contrats', yMax: '350px' }}
                  >
                    <Table<any>
                      handleEvent={{
                        paging: (value: any) => {
                          setParamsUrl({
                            ...getParamsUrl,
                            contractsPage: value.page,
                            contractsLimit: value.limit,
                          });
                        },
                      }}
                      data={
                        operator
                          ? {
                              list: operator.contracts?.list.map(
                                ({ isEnabled, category, ...rest }) => {
                                  return {
                                    ...rest,
                                    isEnabled: (
                                      <Badge
                                        config={{
                                          color: isEnabled
                                            ? 'success'
                                            : 'error',
                                          text: isEnabled ? 'Actif' : 'Inactif',
                                        }}
                                      />
                                    ),
                                    category: (
                                      <Badge
                                        config={{
                                          text: category,
                                        }}
                                      />
                                    ),
                                  };
                                },
                              ),
                              paging: operator.contracts?.paging,
                            }
                          : undefined
                      }
                      config={{
                        columns: [
                          {
                            title: 'ID',
                            key: 'ospContractId',
                          },
                          {
                            title: 'Catégorie',
                            key: 'category',
                          },
                          {
                            title: 'Start',
                            key: 'startDate',
                          },
                          {
                            title: 'Stop',
                            key: 'stopDate',
                          },
                          {
                            title: 'Activé',
                            key: 'isEnabled',
                          },
                        ],
                      }}
                    />
                  </Widget>
                  <Space config={{ count: 2 }} />
                </>
              )}

              {operator?.poolContracts && (
                <>
                  <Widget
                    state={{ loading, error, refetch }}
                    config={{ title: 'Liste des contrats pool', yMax: '350px' }}
                  >
                    <Table<any>
                      handleEvent={{
                        paging: (value: any) => {
                          setParamsUrl({
                            ...getParamsUrl,
                            poolContractsPage: value.page,
                            poolContractsLimit: value.limit,
                          });
                        },
                      }}
                      data={
                        operator
                          ? {
                              list: operator.poolContracts?.list.map(
                                ({ category, ...rest }) => {
                                  return {
                                    ...rest,
                                    category: (
                                      <Badge
                                        config={{
                                          text: category,
                                        }}
                                      />
                                    ),
                                  };
                                },
                              ),
                              paging: operator.poolContracts?.paging,
                            }
                          : undefined
                      }
                      config={{
                        columns: [
                          {
                            title: 'ID',
                            key: 'ospContractId',
                          },
                          {
                            title: 'Catégorie',
                            key: 'category',
                          },
                          {
                            title: 'Name 1',
                            key: 'name1',
                          },
                          {
                            title: 'Name 2',
                            key: 'name2',
                          },
                          {
                            title: 'Spaces allocated',
                            key: 'spacesAllocated',
                          },
                          {
                            title: 'Spaces occupied',
                            key: 'spacesOccupied',
                          },
                        ],
                      }}
                    />
                  </Widget>
                </>
              )}
            </Col>
          </Row>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
