import { InfoWindow } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { MapsContext, MapsType } from '../maps.component';
import { Code } from '../../code/code.component';

export const MapsEditorModule = observer(() => {
  const {
    load,
    store: { mode, currentInfoWindow, overlaysDrawing, saveOverlayDelete },
    actions: {
      setMode,
      setCurrentInfoWindow,
      setOverlaysDrawing,
      setSaveOverlayDelete,
      setAnOverlayIsEditing,
    },
    modules: { infoWindow, editor },
    overlaysInitial,
    dataFilter,
  } = useContext(MapsContext);

  return load && editor ? (
    <Code
      config={{
        yMax: '100%',
        readOnly: true, // mode === 'read',
        lang: 'json',
      }}
      data={{
        defaultValue: JSON.stringify(dataFilter, null, 2),
      }}
      className="maps-editor"
    />
  ) : null;
});
