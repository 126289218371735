import { DocumentType } from '@gimlite/watermelon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Languages } from '../types/lang.type';
import { useRootStore } from './useRootStore.hook';

export const useTranslation = () => {
  const { AppStore, I18NStore } = useRootStore();

  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [defaultDocument, setDefaultDocument] = useState<DocumentType>();

  const lang = I18NStore.lang;
  const dictionary = I18NStore.dictionary;

  const currentDocument = useMemo(() => {
    return documents.find((document) => document.lang === lang);
  }, [lang, documents]);

  useEffect(() => {
    if (!documents.length && dictionary && dictionary.documents) {
      setDocuments(dictionary.documents);
      const defaultDocument = documents.find(
        ({ lang: langInner }) => langInner === lang,
      );
      setDefaultDocument(defaultDocument);
    }
  }, [dictionary]);

  const t = useCallback(
    (tag: string, replaces?: Record<string, string>): string => {
      if (!tag) return '';
      let text = `${tag}`;
      // if (!currentDocument) return ''
      if (currentDocument?.translations?.[tag])
        text = currentDocument?.translations?.[tag];
      else if (defaultDocument?.translations?.[tag])
        text = '#' + defaultDocument?.translations?.[tag];
      else text = `##${tag}`;

      // Handle orphan words into debug mode
      if (AppStore?.debug && text.startsWith('#')) {
        // dispatch('REGISTER_ORPHAN_WORD', { word: tag });
      }

      if (replaces) {
        // Handle replacements
        return Object.entries(replaces).reduce(
          (acc, [key, value]) => acc.replaceAll(key, value),
          text,
        );
      }

      return text;
    },
    [currentDocument],
  );

  return {
    lang: (currentDocument?.lang as Languages | undefined) || null,
    t,
  };
};
