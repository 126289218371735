import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Row,
  Select,
  SelectType,
  useQuery,
  WizardItemType,
  Write,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Query, QueryClientsArgs } from '../../../../client/graphql';
import { sortAlphabetically } from '../../../common/array';
import { clientsGql } from '../../createSite/gql/clients.gql';
import { CreateTariffWizardContext } from '../createTariff.wizard';
export const langsItemsSelect: SelectType.Data.Item[] = [
  {
    value: 'fr_FR',
    label: 'Français',
  },
  {
    value: 'en_EN',
    label: 'Anglais',
  },
  {
    value: 'de_DE',
    label: 'Allemand',
  },
  {
    value: 'it_IT',
    label: 'Italien',
  },
];
export const InformationStep = ({
  context: { translation },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateTariffWizardContext>) => {
  const formattedTranslateLang = useMemo(() => {
    console.log({ translation });
    return langsItemsSelect.map(({ value, label }: SelectType.Data.Item) => ({
      value,
      label,
      disabled: translation
        ? translation
            .filter(Boolean)
            .map((translate) => translate.lang)
            .includes(value as string)
        : false,
    }));
  }, [translation]);

  const clientsQuery = useQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    variables: { limit: 90 },
  });

  const clients = clientsQuery.data?.clients;

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Col config={{ gap: 30, width: 'full', vertical: 'center' }}>
          <Col config={{ gap: 20, width: 'full', vertical: 'center' }}>
            <Write
              data={{ item: 'Informations du tarif' }}
              config={{
                mode: 'title-small',
              }}
            ></Write>
            <Row config={{ gap: 20 }}>
              <Form.Item
                config={{
                  way: 'vertical',
                  name: 'clientId',
                  label: 'Clients',
                  rules: [{ required: true }],
                }}
              >
                <Select
                  config={{
                    width: 'full',
                    height: 'xlarge',
                  }}
                  data={{
                    items: clients
                      ? sortAlphabetically(
                          clients.list.map(({ slug, _id }: any) => ({
                            label: slug,
                            value: _id,
                          })),
                          'label',
                        )
                      : [],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  way: 'vertical',
                  name: 'code',
                  label: 'Code',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  config={{ placeholder: '', width: 'full', height: 'xlarge' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col config={{ gap: 20, width: 'full', vertical: 'center' }}>
            <Write
              data={{ item: 'Nom du parking dans le menu' }}
              config={{
                mode: 'title-small',
              }}
            ></Write>
            <Row config={{ gap: 20 }}>
              <Form.List
                config={{
                  name: 'translation',
                }}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Col config={{ gap: 20, width: 'full' }}>
                    {fields.map(({ name, key, ...restOfField }: any, index) => {
                      return (
                        <Row
                          key={key}
                          config={{
                            vertical: 'end',
                            width: 'full',
                            gap: 20,
                          }}
                        >
                          <Form.Item
                            {...restOfField}
                            config={{
                              way: 'vertical',
                              label: 'Langue',
                              name: [name, 'lang'],
                              rules: [{ required: true }],
                            }}
                          >
                            <Select
                              data={{
                                items: formattedTranslateLang,
                              }}
                              config={{
                                height: 'xlarge',
                                width: 'medium',
                              }}
                            ></Select>
                          </Form.Item>

                          <Form.Item
                            {...restOfField}
                            config={{
                              way: 'vertical',
                              label: 'Nom du tarif',
                              name: [name, 'name'],
                              rules: [{ required: true }],
                            }}
                          >
                            <Input
                              config={{
                                height: 'xlarge',
                                width: 'full',
                                placeholder: 'Tarif Résident',
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restOfField}
                            config={{
                              way: 'vertical',
                              label: 'Description du tarif',
                              name: [name, 'description'],
                              rules: [{ required: true }],
                            }}
                          >
                            <Input
                              config={{
                                height: 'xlarge',
                                width: 'full',
                                placeholder: 'Dédie à la résidence',
                              }}
                            />
                          </Form.Item>

                          <div className="formListButtonContain">
                            <div
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => remove(name)}
                            >
                              <Icon
                                config={{
                                  type: 'faSquareMinusSolid',
                                  size: 'large',
                                  color: 'primary',
                                }}
                              />
                            </div>
                          </div>
                        </Row>
                      );
                    })}
                    {fields.length < formattedTranslateLang.length && (
                      <Row config={{ horizontal: 'center' }}>
                        <Button
                          handleEvent={{
                            click: () => add(),
                          }}
                          config={{
                            text: 'Ajouter un nom de parking dans une autre langue',
                          }}
                        />
                      </Row>
                    )}
                  </Col>
                )}
              </Form.List>
            </Row>
          </Col>
        </Col>
      </Form.Structure>
    </Form>
  );
};
