import {
  ApolloError,
  DocumentNode,
  LazyQueryExecFunction,
  LazyQueryHookOptions,
  MaybeMasked,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery as useLazyQueryApolloClient,
} from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationModeType } from '../store/notification.store';
import { useRootStore } from './useRootStore.hook';

const isAPaging = (data: any) => {
  try {
    const [key, value]: any = Object.entries(data)[0];

    if ('paging' in data?.[key] && 'list' in data?.[key]) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> & {
    notification?: {
      loading?: string;
      success?: string | ((data: TData) => string);
      error?: string | ((error: ApolloError) => string);
    };
    socket?: Record<
      string,
      (data: any, currentData: TData | undefined) => boolean
    >;
  },
): [
  LazyQueryExecFunction<TData, TVariables>,
  QueryResult<TData, TVariables> & {
    count: number;
    resetData: () => void;
  },
] {
  const { NotificationStore, SocketStore } = useRootStore();
  const [dataSaved, setDataSaved] = useState<MaybeMasked<TData>>();

  const [stateInterpreter, setStateInterpreter] = useState<{
    loading: boolean | null;
    data: TData | null;
    error: ApolloError | null;
  }>({
    loading: null,
    data: null,
    error: null,
  });

  const useLazyQueryApolloClientResult = useLazyQueryApolloClient<
    TData,
    TVariables
  >(query, {
    pollInterval: 0,
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: null,
      }));

      setStateInterpreter({
        data: data,
        loading: false,
        error: null,
      });
    },
    onError: (error) => {
      setNotificationKey((prev) => ({
        ...prev,
        ERROR: null,
      }));

      setStateInterpreter({
        data: null,
        loading: false,
        error: error,
      });
    },
    ...options,
  });

  const [call, state] = useLazyQueryApolloClientResult;

  const callCountRef = useRef(0);
  const notification = options?.notification;
  const [notificationKey, setNotificationKey] = useState<
    Record<
      Extract<NotificationModeType, 'LOADING' | 'SUCCESS' | 'ERROR'>,
      string | null
    >
  >({
    LOADING: null,
    SUCCESS: null,
    ERROR: null,
  });

  const intermediateCall: LazyQueryExecFunction<TData, TVariables> =
    useCallback(
      async (data) => {
        setStateInterpreter(() => ({
          data: null,
          loading: true,
          error: null,
        }));

        callCountRef.current++;
        return call(data);
      },
      [call, setStateInterpreter],
    );

  const count = useMemo(() => callCountRef.current, [callCountRef.current]);

  useEffect(() => {
    if (!notification) return;

    if (
      notification?.loading &&
      stateInterpreter.loading &&
      !notificationKey.LOADING
    ) {
      const keyLoading = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'LOADING',
        content: notification.loading,
        duration: 100,
        key: keyLoading,
      });

      setNotificationKey((prev) => ({
        ...prev,
        LOADING: keyLoading,
      }));

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.success &&
      stateInterpreter.data &&
      !notificationKey.SUCCESS
    ) {
      const keySuccess = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'SUCCESS',
        content:
          typeof notification.success === 'function'
            ? notification.success(stateInterpreter.data)
            : notification.success,
        key: keySuccess,
      });

      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: keySuccess,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.error &&
      stateInterpreter.error &&
      !notificationKey.ERROR
    ) {
      const keyError = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'ERROR',
        content:
          typeof notification.error === 'function'
            ? notification.error(stateInterpreter.error)
            : notification.error,
        key: keyError,
      });

      setNotificationKey((prev) => ({
        ...prev,
        ERROR: keyError,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }
    }
  }, [
    notification,
    stateInterpreter,
    NotificationStore,
    notificationKey,
    count,
  ]);

  useEffect(() => {
    if (!options?.socket) return;
  }, [options?.socket, SocketStore.client]);

  useEffect(() => {
    if (SocketStore.client && options?.socket) {
      Object.entries(options.socket).forEach(([key, value]) => {
        SocketStore.client!.on(key, (data) => {
          if (value(data, dataSaved)) {
            if (isAPaging(state.data)) {
              state.refetch({ ...state.variables, page: 1 } as any);
            } else {
              if (dataSaved) {
                state.refetch();
              }
            }
          }
        });
      });
    }

    return () => {
      if (SocketStore.client && options?.socket) {
        Object.entries(options.socket).forEach(([key, value]) => {
          SocketStore.client!.off(key, (data) => {
            if (value(data, state.data)) {
              if (dataSaved) {
                state.refetch();
              }
            }
          });
        });
      }
    };
  }, [
    SocketStore.client?.connected,
    options?.socket,
    state.refetch,
    dataSaved,
  ]);

  useEffect(() => {
    if (state.data) {
      setDataSaved(state.data);
    }
  }, [state.data]);

  // //! TODO: unmount null savedData
  // useEffect(() => {
  //   return () => setDataSaved(undefined);
  // }, [location.pathname]);

  return [
    intermediateCall,
    {
      ...state,
      refetch: (variables?: Partial<TVariables> | undefined) => {
        //! DOTO --> onComplete et onError se ne déclenche pas avec un refetch
        // setStateInterpreter(() => ({
        //   data: null,
        //   loading: true,
        //   error: null,
        // }));

        callCountRef.current++;
        return state.refetch(variables);
      },
      count,
      data: dataSaved,
      resetData: () => setDataSaved(undefined),
    },
  ];
}
