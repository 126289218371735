import { Button, Col, Icon } from '@gimlite/watermelon';
import { Checkbox } from '@gimlite/watermelon/components/checkbox/checkbox.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import {
  CreatePrestoScanWizardContext,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import './general.scss';

export const VaoStep = ({
  context: { createVAO, searchParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  return (
    <Form
      data={{ value: createVAO }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ createVAO: value });
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Write
          data={{ item: 'Configuration des différents ups' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'sitesLegacyId',
              label: "Code de l'UPS dont les horodateurs sont surveillés",
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'text' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Col>
          <Form.List
            data={{
              defaultValues: [],
            }}
            config={{
              name: 'rightsLegacyIds',
            }}
          >
            {(fields, { add, remove }, { errors }) => (
              <Col config={{ gap: 20, width: 'full' }}>
                {fields.map(({ name, key, ...restOfField }: any, index) => (
                  <Row
                    key={key}
                    config={{
                      vertical: 'end',
                      horizontal: 'end',
                      width: 'full',
                      gap: 20,
                    }}
                  >
                    <Form.Item
                      {...restOfField}
                      config={{
                        way: 'vertical',
                        label:
                          'Code UPS dont les droits sont valides dans le parking',
                        name: [name, 'id'],
                        rules: [{ required: true }],
                      }}
                    >
                      <Input
                        config={{
                          height: 'xlarge',
                          width: 'full',
                        }}
                      />
                    </Form.Item>

                    <div className="formListButtonContain">
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => remove(name)}
                      >
                        <Icon
                          config={{
                            type: 'faSquareMinusSolid',
                            size: 'large',
                            color: 'primary',
                          }}
                        />
                      </div>
                    </div>
                  </Row>
                ))}

                <Row config={{ horizontal: 'center', width: 'full' }}>
                  <Button
                    handleEvent={{
                      click: () => add(),
                    }}
                    config={{
                      text: 'Ajouter un code UPS dont les droit sont valides',
                    }}
                  />
                </Row>
              </Col>
            )}
          </Form.List>
        </Col>
        <Write
          data={{ item: 'Périodes de tolérance' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'entryTolerancePeriod',
              label: 'Durée de tolérance après entrée (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'exitTolerancePeriod',
              label: 'Durée de tolérance rétroactive avant sortie (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Durées de conservation des données dans PrestoScan' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_incomplete',
              label: 'Données incomplètes (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_complete',
              label: 'Sessions complètes et en règle (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_toCheck',
              label: 'Sessions à contrôler (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_unfined',
              label: 'Sessions contrôlées et non verbalisées (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_fined',
              label: 'Sessions contrôlées et verbalisées (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Paliers VAO de défaut de droit' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'outrageTreshold_warning',
              label: 'Orange (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'outrageTreshold_danger',
              label: 'Rouge (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Ne pas proposer en VAO les sessions trop longues' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'maxSessionDurationInHours',
              label:
                'Durée (en heures; valeur entre 4h et 120h ; conseillé : 24h)',
              rules: [
                {
                  required: true,
                  validator: (rules, value) => {
                    return new Promise((resolve, reject) => {
                      const regex = /^[0-9]+$/; // Allows only numbers (no letters or symbols)

                      if (!value) {
                        reject(new Error('La durée est requise.'));
                      } else if (!regex.test(value)) {
                        reject(new Error('Seuls les nombres sont autorisés.'));
                      } else if (value < 4 || value > 120) {
                        reject(
                          new Error(
                            'La valeur doit être comprise entre 4 et 120.',
                          ),
                        );
                      } else {
                        resolve(true);
                      }
                    });
                  },
                },
              ],
            }}
          >
            <Input
              data={{
                defaultValue: 24,
              }}
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'medium',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Fonctionnalités' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              name: 'isVerbalizationAllowed',
              label: 'Droit de verbaliser',
            }}
          >
            <Checkbox
              config={{
                size: 'medium',
              }}
            />
          </Form.Item>
        </Row>
        {/* <Row>
          <Form.Item
            config={{
              name: 'showOccupancy',
              label: "Visualization de l'occupation",
            }}
          >
            <Checkbox
              config={{
                size: 'medium',
              }}
            />
          </Form.Item>
        </Row> */}
      </Form.Structure>
    </Form>
  );
};
