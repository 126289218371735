import { InfoWindow } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { MapsContext, MapsType } from '../maps.component';

const getPathOverlay = (
  e: Extract<
    MapsType.Overlay.Object,
    google.maps.Polygon | google.maps.Polyline
  >,
): Array<MapsType.Location> => {
  return e
    ?.getPath()
    ?.getArray()
    ?.map((e) => ({
      lat: e.lat(),
      lng: e.lng(),
    }));
};

const getPropertiesOverlay = (
  e: Extract<
    MapsType.Overlay.Object,
    google.maps.Polygon | google.maps.Polyline
  >,
): MapsType.PropertiesBase => {
  return {
    strokeColor: e?.get('strokeColor'),
    strokeWeight: e?.get('strokeWeight'),
    strokeOpacity: e?.get('strokeOpacity'),
    fillColor: e?.get('fillColor'),
    fillOpacity: e?.get('fillOpacity'),
    zIndex: e?.get('zIndex'),
  };
};

export const MapsModalModule = observer(() => {
  const {
    load,
    store: {
      mode,
      currentInfoWindow,
      overlaysDrawing,
      saveOverlayDelete,
      overlaysImport,
    },
    actions: {
      setMode,
      setCurrentInfoWindow,
      setOverlaysImport,
      setOverlaysDrawing,
      setSaveOverlayDelete,
      setAnOverlayIsEditing,
    },
    modules: { infoWindow },
    overlaysInitial,
  } = useContext(MapsContext);

  const allOverlays = [
    ...(overlaysDrawing || []),
    ...(overlaysInitial || []),
    ...(overlaysImport || []),
  ];

  //! Attachement des fenêtres d'information aux overlays
  useEffect(() => {
    allOverlays.forEach(({ id, type, overlay }, index) => {
      switch (type) {
        case google.maps.drawing.OverlayType.POLYGON:
          overlay.addListener('click', (e) => {
            if (infoWindow) {
              setCurrentInfoWindow(null);
              const pathLength = overlay.getPaths().getArray().length;

              setTimeout(() => {
                setCurrentInfoWindow({
                  position: {
                    lat: e.latLng?.lat(),
                    lng: e.latLng?.lng(),
                  },
                  content: infoWindow.content({
                    overlay: {
                      building: {
                        id: overlay.get('id'),
                        type,
                        overlay,
                      },
                      type: pathLength > 1 ? 'MultiPolygon' : 'Polygon',
                      path: getPathOverlay(overlay),
                      propertiesNative: getPropertiesOverlay(overlay),
                      allProperties: overlay.get('allProperties'),
                      onChange: (value) => {
                        overlay.set('allProperties', value);
                        setAnOverlayIsEditing(true);

                        const propertieNative = {
                          strokeColor: value?.strokeColor,
                          strokeWeight: value?.strokeWeight,
                          strokeOpacity: value?.strokeOpacity,
                          fillColor: value?.fillColor,
                          fillOpacity: value?.fillOpacity,
                          zIndex: value?.zIndex,
                        };

                        overlay.setOptions(propertieNative);
                      },
                      onDelete: (value) => {
                        setCurrentInfoWindow(null);
                        setSaveOverlayDelete((prev) => [...prev, value]);

                        overlay.setMap(null);
                      },
                      onClose: () => {
                        setCurrentInfoWindow(null);
                      },
                    },

                    mode,
                  }),
                });
              }, 0);
            }
          });

          break;
      }
    });

    return () => {
      allOverlays.forEach(({ overlay }, index) => {
        overlay.unbindAll();
      });
    };
  }, [allOverlays, mode]);

  //! Overture de la fenêtre d'information lors de la création d'un overlay
  useEffect(() => {
    if (overlaysDrawing.length > 0) {
      const lastOverlay = overlaysDrawing[overlaysDrawing.length - 1];

      if (infoWindow) {
        setCurrentInfoWindow(null);
        const pathLength = lastOverlay.overlay.getPaths().getArray().length;

        setTimeout(() => {
          setCurrentInfoWindow({
            position: {
              lat:
                (getPathOverlay(lastOverlay.overlay)[0].lat +
                  getPathOverlay(lastOverlay.overlay)[
                    getPathOverlay(lastOverlay.overlay).length - 1
                  ].lat) /
                2,
              lng:
                (getPathOverlay(lastOverlay.overlay)[0].lng +
                  getPathOverlay(lastOverlay.overlay)[
                    getPathOverlay(lastOverlay.overlay).length - 1
                  ].lng) /
                2,
            },
            content: infoWindow.content({
              overlay: {
                building: {
                  id: lastOverlay.overlay.get('id'),
                  type: lastOverlay.type,
                  overlay: lastOverlay.overlay,
                },
                type: pathLength > 1 ? 'MultiPolygon' : 'Polygon',
                path: getPathOverlay(lastOverlay.overlay),
                propertiesNative: getPropertiesOverlay(lastOverlay.overlay),
                allProperties: {
                  ...lastOverlay.overlay.get('allProperties'),
                  ...getPropertiesOverlay(lastOverlay.overlay),
                },
                onChange: (value) => {
                  lastOverlay.overlay.set('allProperties', value);
                  setAnOverlayIsEditing(true);

                  const propertieNative = {
                    strokeColor: value?.strokeColor,
                    strokeWeight: value?.strokeWeight,
                    strokeOpacity: value?.strokeOpacity,
                    fillColor: value?.fillColor,
                    fillOpacity: value?.fillOpacity,
                    zIndex: value?.zIndex,
                  };

                  lastOverlay.overlay.setOptions(propertieNative);
                },
                onDelete: (value) => {
                  setCurrentInfoWindow(null);
                  setSaveOverlayDelete((prev) => [...prev, value]);

                  lastOverlay.overlay.setMap(null);
                },
                onClose: () => {
                  setCurrentInfoWindow(null);
                },
              },

              mode,
            }),
          });
        }, 0);
      }
    }
  }, [overlaysDrawing]);

  //! Fermeture de la fenêtre d'information lors du changement de mode
  useEffect(() => {
    setCurrentInfoWindow(null);
  }, [mode]);

  return load && infoWindow && currentInfoWindow ? (
    <InfoWindow
      options={{
        maxWidth: 600,
      }}
      onCloseClick={() => {
        setCurrentInfoWindow(null);
      }}
      position={currentInfoWindow.position}
    >
      {currentInfoWindow.content}
    </InfoWindow>
  ) : null;
});
