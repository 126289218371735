import { gql } from '@apollo/client';

export const controlUsersGql = gql`
  query controlUsers($page: Int, $limit: Int, $order: String) {
    controlUsers(page: $page, limit: $limit, order: $order) {
      list {
        _id
        username
        firstName
        lastName
        fpsAgentId
        fpsAgentName
        ups
        state
        lang
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
