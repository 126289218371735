import { gql } from '@apollo/client';

export const autotestsGql = gql`
    query autotests(
        $page: Int,
        $limit: Int,
        $order: String,
        $siteId: String,
    ) {
        autotests(
            page: $page,
            limit: $limit,
            order: $order,
            siteId: $siteId
        ) {
            list {
                _id
                device
                infos
                result
            }

            paging {
                count
                current
                limit
            }
        }
    }
`