import { gql } from '@apollo/client';

export const searchTariffsGql = gql`
  query searchTariffs($clientId: String!, $limit: Int) {
    searchTariffs(clientId: $clientId, limit: $limit) {
      list {
        _id
        clientId
        code
        translation {
          lang
          name
        }
        createdAt
        updatedAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
