import Color from 'color';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { getCSS } from '../../functions/css.function';
import { useRootStore } from '../../hook/useRootStore.hook';
import { Color as ColorGlobal } from '../../types/component.type';
import './avatar.component.scss';

export declare namespace AvatarType {
  type Props = {
    className?: string;
    data: AvatarType.Data;
    config: {
      color: AvatarType.Config.Color;
      backgroundColor: AvatarType.Config.Color;
    };
  };

  type Data = {
    firstName: string;
    lastName: string;
  };

  namespace Config {
    type Color = Extract<
      ColorGlobal,
      'primary-over' | 'primary' | 'yin' | 'yang' | 'white' | 'black'
    >;
  }
}

export const Avatar = observer(
  ({
    data: { firstName, lastName },
    config: { color, backgroundColor },
    className = '',
  }: AvatarType.Props) => {
    const { ThemeStore } = useRootStore();
    // ThemeStore.theme

    const image = useMemo(() => {
      return `https://ui-avatars.com/api/?format=svg&font-size=0.5&bold=true&name=${
        firstName || '?'
      }+${lastName || '?'}&color=${new Color(getCSS(`--color-${color}`))
        .hex()
        .toString()
        .replace('#', '')}&background=${new Color(
        getCSS(`--color-${backgroundColor}`),
      )
        .hex()
        .toString()
        .replace('#', '')}`;
    }, [firstName, lastName, color, backgroundColor, ThemeStore.theme]);

    return (
      <div className={`avatar ${className}`}>
        <img className={`avatar__image`} src={image}></img>
      </div>
    );
  },
);
