import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Easing, Group, Tween } from '@tweenjs/tween.js';
import * as GeoJSON from 'geojson';
import { observer } from 'mobx-react-lite';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDevice } from '../../hook/useDevice.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { NullablePartial } from '../../types/utils.type';
import { Empty } from '../empty/empty.component';
import { useMapsTheme } from './hook/theme.hook';
import './maps.component.scss';
import { MapsDrawingModule } from './modules/drawing.module';
import { MapsEditorModule } from './modules/editor.module';
import { MapsLoaderModule } from './modules/loader.module';
import { MapsModalModule } from './modules/modal.module';

export const GeoJSONToOverlay = <TProperties extends MapsType.PropertiesBase>(
  data: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, TProperties>[],
  mapsTheme: ReturnType<typeof useMapsTheme>,
) => {
  return data?.reduce(
    (acc: Array<MapsType.Overlay.Building>, { geometry, properties }) => {
      switch (geometry.type) {
        case 'Polygon':
          const allPropertiesPolygon = {
            ...properties,
            strokeColor:
              properties?.strokeColor ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeColor,
            strokeOpacity:
              properties?.strokeOpacity ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeOpacity,
            strokeWeight:
              properties?.strokeWeight ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeWeight,
            fillColor:
              properties?.fillColor ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.fillColor,
            fillOpacity:
              properties?.fillOpacity ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.fillOpacity,
            zIndex: properties?.zIndex,
          };

          const polygon = new google.maps.Polygon({
            paths: geometry.coordinates.flat().flatMap(([lng, lat]) => ({
              lat,
              lng,
            })),
            geodesic: true,
            strokeColor: allPropertiesPolygon.strokeColor,
            strokeOpacity: allPropertiesPolygon.strokeOpacity,
            strokeWeight: allPropertiesPolygon.strokeWeight,
            fillColor: allPropertiesPolygon.fillColor,
            fillOpacity: allPropertiesPolygon.fillOpacity,
            zIndex: allPropertiesPolygon.zIndex,
          });

          polygon.set('allProperties', allPropertiesPolygon);
          const polygonId = crypto.randomUUID();
          polygon.set('id', polygonId);
          return [
            ...acc,
            {
              id: polygonId,
              type: google.maps.drawing.OverlayType.POLYGON,
              overlay: polygon,
            } as MapsType.Overlay.Building,
          ];

        case 'MultiPolygon':
          const allPropertiesMultiPolygon = {
            ...properties,
            strokeColor:
              properties?.strokeColor ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeColor,
            strokeOpacity:
              properties?.strokeOpacity ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeOpacity,
            strokeWeight:
              properties?.strokeWeight ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.strokeWeight,
            fillColor:
              properties?.fillColor ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.fillColor,
            fillOpacity:
              properties?.fillOpacity ||
              mapsTheme?.drawingManagerStyle?.polygonOptions?.fillOpacity,
            zIndex: properties?.zIndex,
          };

          const multiPolygon = new google.maps.Polygon({
            paths: geometry.coordinates.flat().map((item) =>
              item.flatMap(([lng, lat]) => ({
                lat,
                lng,
              })),
            ),
            geodesic: true,
            strokeColor: allPropertiesMultiPolygon.strokeColor,
            strokeOpacity: allPropertiesMultiPolygon.strokeOpacity,
            strokeWeight: allPropertiesMultiPolygon.strokeWeight,
            fillColor: allPropertiesMultiPolygon.fillColor,
            fillOpacity: allPropertiesMultiPolygon.fillOpacity,
            zIndex: allPropertiesMultiPolygon.zIndex,
          });

          multiPolygon.set('allProperties', allPropertiesMultiPolygon);

          const multiPolygonId = crypto.randomUUID();
          multiPolygon.set('id', multiPolygonId);
          return [
            ...acc,
            {
              id: multiPolygonId,
              type: google.maps.drawing.OverlayType.POLYGON,
              overlay: multiPolygon,
            } as MapsType.Overlay.Building,
          ];

        default:
          return acc;
      }
    },
    [],
  );
};

export const authorizedTypes = ['Polygon', 'MultiPolygon'] as const;

export declare namespace MapsType {
  type Props<TProperties extends MapsType.PropertiesBase> = {
    className?: string;
    refresh?: string | null;
    data?: MapsType.Data<TProperties> | null;
    defaultPosition?: MapsType.Location | null;
    yMax?: string;
    drawing?: MapsType.Module.Drawing<TProperties>;
    infoWindow?: MapsType.Module.InfoWindow<TProperties>;
    editor?: MapsType.Module.Editor;
    loader?: MapsType.Module.Loader;
  };

  type Data<TProperties extends MapsType.PropertiesBase> =
    GeoJSON.FeatureCollection<
      GeoJSON.Polygon | GeoJSON.MultiPolygon,
      TProperties
    >;

  type Property<TProperties extends MapsType.PropertiesBase> = TProperties;

  type Location = {
    lat: number;
    lng: number;
  };

  type PropertiesBase = {
    strokeColor?: string | null;
    strokeOpacity?: number | null;
    strokeWeight?: number | null;
    fillColor?: string | null;
    fillOpacity?: number | null;
    zIndex: number;
  };

  namespace Module {
    type Drawing<TProperties extends MapsType.PropertiesBase> = {
      modes: {
        polygon?: boolean;
        polyline?: boolean;
      };
      onSubmit?: (data: MapsType.Data<TProperties>) => void;
    };

    type InfoWindow<TProperties extends MapsType.PropertiesBase> = {
      content: (data: {
        overlay: {
          building: Overlay.Building;
          type: string;
          path: Array<MapsType.Location>;
          propertiesNative: MapsType.Property<TProperties>;
          allProperties: MapsType.Property<TProperties>;
          onChange: (
            value: NullablePartial<MapsType.Property<TProperties>>,
          ) => void;
          onDelete: (value: Overlay.Building) => void;
          onClose: () => void;
        };
        mode: MapsType.Mode;
      }) => React.ReactNode;
    };

    type Editor = {
      geojson?: boolean;
    };

    type Loader = {
      loading: boolean;
    };
  }

  type Mode = 'read' | 'edit';

  namespace Overlay {
    type Object =
      | google.maps.Polygon
      | google.maps.Polyline
      | google.maps.Marker
      | google.maps.Rectangle
      | google.maps.Circle;

    type Type =
      | google.maps.drawing.OverlayType.POLYGON
      | google.maps.drawing.OverlayType.POLYLINE
      | google.maps.drawing.OverlayType.MARKER
      | google.maps.drawing.OverlayType.RECTANGLE
      | google.maps.drawing.OverlayType.CIRCLE;

    type Building = {
      id: string;
      type: Extract<Overlay.Type, google.maps.drawing.OverlayType.POLYGON>;
      overlay: Extract<Overlay.Object, google.maps.Polygon>;
    };
  }
}

export const MapsContext = createContext<{
  load: boolean;

  map: google.maps.Map | null;
  store: {
    mode: MapsType.Mode;
    currentInfoWindow: {
      position: MapsType.Location;
      content: React.ReactNode;
    } | null;
    overlaysDrawing: Array<MapsType.Overlay.Building>;
    overlaysImport: Array<MapsType.Overlay.Building>;
    saveOverlayInitial: Array<MapsType.Overlay.Building>;
    saveOverlayDelete: Array<MapsType.Overlay.Building>;
    anOverlayIsEditing: boolean;
  };
  actions: {
    setMode: React.Dispatch<React.SetStateAction<MapsType.Mode>>;
    setCurrentInfoWindow: React.Dispatch<
      React.SetStateAction<{
        position: MapsType.Location;
        content: React.ReactNode;
      } | null>
    >;
    setOverlaysDrawing: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setOverlaysImport: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setSaveOverlayInitial: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setSaveOverlayDelete: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setAnOverlayIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  };
  overlaysInitial: Array<MapsType.Overlay.Building>;
  modules: {
    drawing?: MapsType.Module.Drawing<any>;
    infoWindow?: MapsType.Module.InfoWindow<any>;
    editor?: MapsType.Module.Editor;
    loader?: MapsType.Module.Loader;
  };
  dataFilter: MapsType.Data<any>['features'];
}>({
  load: false,
  map: null,
  store: {
    mode: 'read',
    currentInfoWindow: null,
    overlaysDrawing: [],
    overlaysImport: [],
    saveOverlayInitial: [],
    saveOverlayDelete: [],
    anOverlayIsEditing: false,
  },
  actions: {
    setMode: () => 'read',
    setCurrentInfoWindow: () => null,
    setOverlaysDrawing: () => [],
    setOverlaysImport: () => [],
    setSaveOverlayInitial: () => [],
    setSaveOverlayDelete: () => [],
    setAnOverlayIsEditing: () => false,
  },
  modules: {},
  overlaysInitial: [],
  dataFilter: [],
});

export const Maps = observer(
  <TProperties extends MapsType.PropertiesBase>({
    yMax = '100%',
    data,
    refresh,
    defaultPosition,
    className = '',
    infoWindow,
    drawing,
    editor,
    loader,
  }: MapsType.Props<TProperties>) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const { MapsStore } = useRootStore();
    const { isMobile } = useDevice();
    const { isLoaded: load } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: MapsStore.token || '',
      libraries: ['drawing'],
    });
    const requestAnimationId = useRef<number | null>(null);
    const mapsTheme = useMapsTheme({ load });

    const [mode, setMode] = useState<'read' | 'edit'>('read');

    const [currentInfoWindow, setCurrentInfoWindow] = useState<{
      position: MapsType.Location;
      content: React.ReactNode;
    } | null>(null);

    const [overlaysDrawing, setOverlaysDrawing] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    const [overlaysImport, setOverlaysImport] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    const [anOverlayIsEditing, setAnOverlayIsEditing] =
      useState<boolean>(false);

    const [saveOverlayInitial, setSaveOverlayInitial] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    const [saveOverlayDelete, setSaveOverlayDelete] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    //! Trie les types géométriques GeoJSON gérer dans le composant
    const dataFilter = useMemo(() => {
      if (!data) return [];
      return data?.features.filter((e) =>
        authorizedTypes.includes(e.geometry.type),
      );
    }, [data]);

    //! Transforme les types géométriques GeoJSON en objets Google Maps
    const dataOverlay = useMemo(() => {
      if (!load) return null;
      return GeoJSONToOverlay<TProperties>(dataFilter, mapsTheme);
    }, [dataFilter, refresh, load]);

    //! Chargement des overlays
    useEffect(() => {
      if (load && map !== null && dataOverlay) {
        map.data.forEach((feature) => map.data.remove(feature));
        map.overlayMapTypes.clear();

        setCurrentInfoWindow(() => null);
        setMode('read');

        setTimeout(() => {
          dataOverlay.forEach(({ overlay }) => {
            overlay.setVisible(true);
            overlay.setMap(map);
            overlay.setEditable(false);
            overlay.setDraggable(false);
          });
        }, 0);
      }

      return () => {
        if (map) {
          map.data.forEach((feature) => map.data.remove(feature));
          map.overlayMapTypes.clear();
        }
        dataOverlay?.forEach(({ overlay }) => {
          overlay.setVisible(false);
          overlay.setMap(null);
        });
      };
    }, [dataOverlay, load, map]);

    //! Déplacement de la caméra à chaque nouvelle position
    useEffect(() => {
      if (!map || !defaultPosition || !load) return;

      const currentLatLng = new google.maps.LatLng(
        defaultPosition.lat,
        defaultPosition.lng,
      );

      const cameraOptions: google.maps.CameraOptions = {
        zoom: 0,
        heading: 0,
        tilt: 0,
        center: currentLatLng,
      };

      const group = new Group();

      const loop = (time: number) => {
        console.log('requestAnimationFrame');
        group.update(time);
        requestAnimationId.current = requestAnimationFrame(loop);
      };

      requestAnimationId.current = requestAnimationFrame(loop);

      const tween = new Tween(cameraOptions)
        .to({ zoom: 13, tilt: 180, heading: 180 }, 2500)
        .easing(Easing.Quadratic.Out)
        .onUpdate(() => {
          map.moveCamera(cameraOptions);
        })
        .onStop(() => {
          if (requestAnimationId.current) {
            cancelAnimationFrame(requestAnimationId.current);
          }
        })
        .onComplete(() => {
          tween.stop();
        })
        .start();

      group.add(tween);

      return () => {
        group.allStopped();

        if (requestAnimationId.current) {
          cancelAnimationFrame(requestAnimationId.current);
        }
      };
    }, [map, defaultPosition, load, requestAnimationId]);

    return load ? (
      <div className="maps" style={{ height: yMax }}>
        <MapsContext.Provider
          value={{
            load,
            map,
            store: {
              mode,
              currentInfoWindow,
              overlaysDrawing,
              overlaysImport,
              saveOverlayInitial,
              saveOverlayDelete,
              anOverlayIsEditing,
            },
            actions: {
              setMode,
              setCurrentInfoWindow,
              setOverlaysDrawing,
              setOverlaysImport,
              setSaveOverlayInitial,
              setSaveOverlayDelete,
              setAnOverlayIsEditing,
            },
            modules: {
              drawing,
              infoWindow,
              editor,
              loader,
            },
            overlaysInitial: dataOverlay || [],
            dataFilter,
          }}
        >
          <div className="maps__contain">
            <GoogleMap
              mapContainerClassName={`
            maps__contain__canvas
            ${className}
          `}
              options={{
                ...(mapsTheme?.mapStyle || {}),
                scrollwheel: isMobile ? false : true,
                gestureHandling: 'greedy',
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                zoomControl: true,
                mapTypeControl: true,
                panControl: true,
                scaleControl: true,
                minZoom: 3,
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
              }}
              onClick={(e) => {
                setCurrentInfoWindow(null);
              }}
              onLoad={(e) => {
                const bounds = new google.maps.LatLngBounds(defaultPosition);
                e.fitBounds(bounds, 0);
                setMap(e);
              }}
            >
              <>
                <MapsDrawingModule />
                <MapsModalModule />
                <MapsLoaderModule />
              </>
            </GoogleMap>
            <MapsEditorModule />
          </div>
        </MapsContext.Provider>
      </div>
    ) : (
      <Empty config={{ mode: { name: 'disabled' } }} />
    );
  },
);
