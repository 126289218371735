import type { FunctionComponent } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Color } from '../../types/component.type';
import { Button } from '../button/button.component';
import { Col } from '../col/col.component';
import { Description } from '../description/description.component';
import { ElevateContext } from '../elevate/elevate.component';
import { Icon, IconType } from '../icon/icon.component';
import { Loading } from '../loading/loading.component';
import { Popover } from '../popover/popover.component';
import { Result } from '../result/result.component';
import { Slider } from '../slider/slider.component';
import { Steps } from '../steps/steps.component';
import { Write } from '../write/write.component';
import './wizard.component.scss';

export declare namespace WizardType {
  type Props<T> = {
    className?: string;
    data: {
      defaultContext: T;
    };
    handleEvent?: {
      error?: (value: boolean | string | undefined) => void;
      clear?: (value: boolean) => void;
      done?: (value: T) => void;
      close?: () => void;
    };
    config?: {
      title?: string;
      withNavigation?: boolean;
      withValidation?: {
        state?: boolean;
        success?: string;
        error?: string;
        details?: Array<WizardType.Config.Details>;
      };
    };
    children: React.ReactNode;
  };

  namespace Config {
    type Details = {
      type: 'success' | 'error';
      title: string;
      description?: string;
    };
  }
}

export const Wizard = <T,>({
  className = '',
  data: { defaultContext },
  config: { title, withNavigation = true, withValidation } = {},
  children,
  handleEvent: { error, clear, done, close } = {},
}: WizardType.Props<T>) => {
  const { t, lang } = useTranslation();

  const [context, setContext] = useState<T>(defaultContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingStep, setLoadingStep] = useState(false);
  const [stepValidation, setValidation] = useState<boolean>(false);
  const [stepError, setStepError] = useState<boolean | string | undefined>();

  const childrenFormatted = useMemo(
    (): React.ReactElement[] =>
      (Array.isArray(children) ? children : [children]).filter(Boolean),
    [children],
  );

  const maxStep = useMemo(
    () =>
      withValidation ? childrenFormatted.length : childrenFormatted.length - 1,
    [childrenFormatted, withValidation],
  );

  const hasPreviousButton = useMemo(
    () => currentStep > 0 && currentStep < maxStep,
    [currentStep, maxStep],
  );

  const hasNextButton = useMemo(
    () => currentStep < maxStep - 1,
    [currentStep, maxStep],
  );

  const isFinalStep = useMemo(
    () => currentStep === maxStep - 1,
    [, currentStep, maxStep],
  );

  const isFinishStep = useMemo(
    () => currentStep === maxStep,
    [currentStep, maxStep],
  );

  const CurrentComponent = useMemo(
    (): FunctionComponent<WizardItemType.Config.Component<T>> =>
      childrenFormatted.find((element, index) => index === currentStep)?.props
        ?.config?.component,
    [currentStep, childrenFormatted],
  );

  const formattedStep = useMemo(() => {
    const build = [
      ...childrenFormatted.map((child: React.ReactElement, index) => ({
        title: child?.props?.config?.title,
        key: `step-wizard-item-${index}`,
      })),
    ];

    if (withValidation)
      build.push({
        key: 'step-wizard-item-validation',
        title: 'Validation',
      });

    return build;
  }, [childrenFormatted, withValidation]);

  useEffect(() => {
    error && error(stepError);
  }, [stepError]);

  useEffect(() => {
    console.log({ context });
  }, [context]);

  return (
    <ElevateContext.Consumer>
      {({ close: closeElevate }) => (
        <div
          style={{
            gridTemplateRows: `
              min-content
              min-content
              1fr
              ${withNavigation ? 'min-content' : ''}`,
          }}
          className={`wizard ${className}`}
        >
          <div
            onClick={() => {
              closeElevate?.();
              close?.();
            }}
            className="wizard__close"
          >
            <Icon config={{ type: 'faXmarkSolid', size: 'xlarge' }} />
          </div>
          <div className="wizard__title">
            <Write
              data={{ item: title ?? '' }}
              config={{ mode: 'title-medium', lineHeight: true }}
            />
          </div>

          <div className="wizard__steps">
            <div
              onClick={() => clear && clear(true)}
              className="wizard__steps__retry"
            ></div>
            <Steps
              config={{
                current: currentStep,
              }}
              data={{
                items: formattedStep,
              }}
            />
          </div>
          <div className="wizard__form">
            <div className="wizard__form__contain">
              <div
                style={{ display: `${loadingStep ? 'none' : 'block'}` }}
                className="wizard__form__contain__item"
              >
                {CurrentComponent && !withValidation?.state && (
                  <CurrentComponent
                    loading={(value: boolean) => {
                      setLoadingStep(value);
                    }}
                    context={context! ?? {}}
                    validate={(error: boolean | string) => {
                      if (typeof error === 'boolean') {
                        setValidation(error);
                        setStepError(undefined);
                      } else {
                        setValidation(false);
                        setStepError(error);
                      }
                    }}
                    submit={(data) => {
                      setContext((currentContext) => ({
                        ...currentContext,
                        ...data,
                      }));
                    }}
                  />
                )}

                {withValidation && isFinishStep && (
                  <div className="wizard__form__contain__item__finish">
                    {withValidation?.state === undefined && (
                      <Loading config={{ size: 'medium' }} />
                    )}

                    {withValidation?.state === true && (
                      <Result
                        data={{
                          status: 'success',
                          title: withValidation?.success,
                        }}
                      />
                    )}

                    {withValidation?.state === false && (
                      <Col
                        config={{ vertical: 'center', horizontal: 'center' }}
                      >
                        <Result
                          data={{
                            status: 'error',
                            title: withValidation?.error,
                          }}
                        />

                        {withValidation.details &&
                          withValidation.details.length > 0 && (
                            <Popover
                              data={
                                <Description
                                  data={withValidation.details.map(
                                    ({ title, type, description }, index) => ({
                                      key: `withValidationDetails-${index}`,
                                      value:
                                        description || t(`watermelon-${type}`),
                                      label: title,
                                      color: type,
                                    }),
                                  )}
                                  config={{ layout: 'column' }}
                                ></Description>
                              }
                            >
                              <Write
                                data={{ item: t('watermelon-showDetails') }}
                                config={{ mode: 'link', color: 'primary' }}
                              ></Write>
                            </Popover>
                          )}
                      </Col>
                    )}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: `${!loadingStep ? 'none' : 'block'}`,
                }}
                className="wizard__form__contain__spin"
              >
                <div className="wizard__form__contain__spin__contain">
                  <Loading config={{ size: 'medium' }} />
                </div>
              </div>
            </div>
          </div>
          {withNavigation && (
            <div className="wizard__nav">
              <div className="wizard__nav__part">
                {hasPreviousButton && (
                  <Button
                    className="wizard__nav__part__button"
                    config={{
                      text: t('watermelon-previous'),
                      mode: 'stroke',
                      size: 'large',
                    }}
                    handleEvent={{
                      click: () => setCurrentStep((index) => index - 1),
                    }}
                  ></Button>
                )}
              </div>
              <div className="wizard__nav__part">
                {hasNextButton && (
                  <Button
                    className="wizard__nav__part__button"
                    config={{
                      text: t('watermelon-next'),
                      disabled: !stepValidation,
                      testId: 'wizard-next',
                      size: 'large',
                    }}
                    handleEvent={{
                      click: () => setCurrentStep((index) => index + 1),
                    }}
                  ></Button>
                )}
                {isFinalStep && (
                  <Button
                    className="wizard__nav__part__button"
                    config={{
                      text: t('watermelon-confirm'),
                      disabled: !stepValidation,
                      testId: 'wizard-confirm',
                      size: 'large',
                    }}
                    handleEvent={{
                      click: () => {
                        setCurrentStep((index) => index + 1);
                        done && context && done(context);
                      },
                    }}
                  ></Button>
                )}
                {isFinishStep && (
                  <Button
                    className="wizard__nav__part__button"
                    config={{
                      text: t('watermelon-close'),
                      disabled: !stepValidation,
                      testId: 'wizard-close',
                      size: 'large',
                    }}
                    handleEvent={{
                      click: () => {
                        close?.();
                      },
                    }}
                  ></Button>
                )}
              </div>
            </div>
          )}
          <div className="wizard__error">
            {stepError && <span>{stepError}</span>}
          </div>
        </div>
      )}
    </ElevateContext.Consumer>
  );
};

export declare namespace WizardItemType {
  type Props<T> = {
    config: {
      title: string;
      component: FunctionComponent<WizardItemType.Config.Component<T>>;
    };
  };

  namespace Config {
    type Component<T> = {
      context: T;
      validate: (value: boolean | string) => void;
      submit: (value: Partial<T>) => void;
      loading: (value: boolean) => void;
    };
  }
}

Wizard.Item = <T,>({}: WizardItemType.Props<T>) => {
  return <></>;
};

export declare namespace WizardCardGroupType {
  interface Props {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    config: {
      title: string;
      subtitle?: string;
      mode?: 'slider' | 'wrapper';
    };
  }
}

Wizard.CardGroup = ({
  className = '',
  children,
  config: { title, subtitle, mode = 'slider' },
}: WizardCardGroupType.Props) => {
  const { t } = useTranslation();

  return (
    <div className={`wizard-card-group ${className}`}>
      <div className="wizard-card-group__text">
        <Write
          className="wizard-card-group__text__title"
          data={{ item: title }}
          config={{ mode: 'title-small' }}
        />
        {subtitle && (
          <Write
            className="wizard-card-group__text__subtitle"
            data={{ item: subtitle }}
            config={{ mode: 'title-small-description' }}
          />
        )}
      </div>
      {mode === 'slider' ? (
        <Slider className="wizard-card-group__slider">{children}</Slider>
      ) : (
        <div className="wizard-card-group__wrapper">{children}</div>
      )}
    </div>
  );
};

export declare namespace WizardCardLinkType {
  interface Props {
    handleEvent?: {
      click?: () => void;
    };
    className?: string;
    config: {
      title: string;
      icon: IconType.Config.Type;
    };
  }

  namespace Config {
    type Key = string;
  }
}

Wizard.CardLink = ({
  className = '',
  handleEvent: { click } = {},
  config: { title, icon },
}: WizardCardLinkType.Props) => {
  return (
    <div
      onClick={() => {
        click?.();
      }}
      className={`wizard-card-link ${className}`}
    >
      <Icon
        className="wizard-card-link__image"
        config={{
          type: icon,
          size: 'giga',
        }}
      />
      <Write
        className="wizard-card-link__text"
        data={{ item: title }}
        config={{ mode: 'title-small', align: 'center' }}
      />
    </div>
  );
};

export declare namespace WizardCardResumeType {
  interface Props {
    className?: string;
    data: {
      title: string;
      resume: WizardCardResumeType.Data.Resume[];
    };
  }

  namespace Data {
    type Resume = {
      show?: boolean;
      label: string;
      value: string;
      color?: Extract<
        Color,
        'success' | 'warn' | 'text' | 'disabled' | 'error'
      >;
    };
  }
}

Wizard.CardResume = ({
  className = '',
  data: { title, resume },
}: WizardCardResumeType.Props) => {
  return (
    <div className={`wizard-card-resume ${className}`}>
      <Write
        className="wizard-card-resume__title"
        data={{ item: title }}
        config={{ mode: 'title-small', align: 'center' }}
      />
      <div className="wizard-card-resume__info">
        {resume
          .filter(({ show = true }) => show)
          .map(({ label, value, color }, index) => (
            <div
              key={`wizard-card-resume__info__line-${index}`}
              className="wizard-card-resume__info__line"
            >
              <Write
                className="wizard-card-resume__info__line__label"
                data={{ item: label }}
                config={{ mode: 'value-small', color: 'label' }}
              />
              <Write
                className="wizard-card-resume__info__line__value"
                data={{ item: value }}
                config={{ mode: 'value-small', color }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export declare namespace WizardCardInfoType {
  interface Props {
    className?: string;
    data: {
      title: string;
      info: WizardCardInfoType.Data.Info;
    };
  }

  namespace Data {
    type Info = React.ReactNode;
  }
}

Wizard.CardInfo = ({
  className = '',
  data: { title, info },
}: WizardCardInfoType.Props) => {
  return (
    <div className={`wizard-card-info ${className}`}>
      <Write
        className="wizard-card-info__title"
        data={{ item: title }}
        config={{ mode: 'title-small', align: 'center' }}
      />
      <div className="wizard-card-info__info">{info}</div>
    </div>
  );
};
