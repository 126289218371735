import { gql } from '@apollo/client';

export const updateTariffGql = gql`
  mutation updateTariff($input: UpdateTariffDto!) {
    updateTariff(input: $input) {
      _id
      description
    }
  }
`;
