import {
  Badge,
  Button,
  Code,
  DatePicker,
  Empty,
  FilterExpanded,
  Form,
  Page,
  Row,
  Select,
  Widget,
  Zone,
  useLazyQuery,
  useMutation,
  useQuery,
  useTranslation,
} from '@gimlite/watermelon';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Mutation,
  MutationUpdateTariffArgs,
  Query,
  QueryReadTariffArgs,
  QuerySearchTariffsArgs,
  QuerySimulateTariffArgs,
} from '../../../../client/graphql';
import { TariffChart } from '../../../charts/tariff.chart';
import { readTariffGql } from '../gql/readTariff.gql';
import { tariffsGql } from '../gql/searchTariffs.gql';
import { simulateTariffGql } from '../gql/simulateTariff.gql';
import { updateTariffGql } from '../gql/updateTariff.gql';

export const SimulateTariffs: React.FC = () => {
  const { lang } = useTranslation();
  const [tariffCode, setTariffCode] = useState<string | null>(null);
  const [isValidFilter, setIsValidFilter] = useState<boolean>(false);
  const [isValidChangeTariff, setIsValidChangeTariff] = useState<boolean>(true);
  const [valueChangeTariff, setValueChangeTariff] = useState<string | null>(
    null,
  );

  const searchTariffsQuery = useQuery<
    Pick<Query, 'searchTariffs'>,
    QuerySearchTariffsArgs
  >(tariffsGql, {
    notification: {
      error: "Une erreur s'est produite lors de la récupération des tarifs.",
    },
  });

  const [readTariffCall, readTariffQuery] = useLazyQuery<
    Pick<Query, 'readTariff'>,
    QueryReadTariffArgs
  >(readTariffGql, {
    notification: {
      error: "Une erreur s'est produite lors de la récupération du tarif.",
    },
  });

  const [simulateTariffCall, simulateTariffQuery] = useLazyQuery<
    Pick<Query, 'simulateTariff'>,
    QuerySimulateTariffArgs
  >(simulateTariffGql, {
    notification: {
      loading: 'Récupération du tarif en cours...',
      success: 'Tarif récupéré avec succès.',
      error: "Une erreur s'est produite lors de la récupération du tarif.",
    },
  });

  const [updateTariffCall, updateTariffQuery] = useMutation<
    Pick<Mutation, 'updateTariff'>,
    MutationUpdateTariffArgs
  >(updateTariffGql, {
    notification: {
      error: "Une erreur s'est produite lors de la mise à jour du tarif.",
      loading: 'Mise à jour du tarif en cours...',
      success: 'Tarif mis à jour avec succès.',
    },
  });

  const tariffs = searchTariffsQuery.data?.searchTariffs;
  const tariff = readTariffQuery.data?.readTariff;
  const simulateTariff = simulateTariffQuery.data?.simulateTariff;
  const updateTariff = updateTariffQuery.data?.updateTariff;

  const valueChangeTariffAndCompare = useMemo(() => {
    return (
      valueChangeTariff &&
      valueChangeTariff.trim() !==
        (updateTariff?.description || tariff?.description)?.trim()
    );
  }, [valueChangeTariff, tariff?.description, updateTariff?.description]);

  useEffect(() => {
    if (tariffCode) {
      readTariffCall({ variables: { input: { code: tariffCode } } });
    }
  }, [tariffCode, readTariffCall]);

  useEffect(() => {
    setValueChangeTariff(null);
    setIsValidChangeTariff(true);
  }, [updateTariff?.description]);

  return (
    <Page config={{ overflow: true }}>
      <Zone
        config={{
          zones: [['left', 'right']],
          rows: ['1fr'],
          columns: ['1fr', '2fr'],
        }}
      >
        <Zone.Area config={{ area: 'left' }}>
          <Zone
            config={{
              zones: [['tariff'], ['code']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'tariff' }}>
              <Widget
                config={{ title: 'Sélectionnez un tarif' }}
                state={{
                  error: searchTariffsQuery.error,
                  loading: searchTariffsQuery.loading,
                  refetch: searchTariffsQuery.refetch,
                }}
              >
                <Form
                  handleEvent={{
                    change: (v) => setTariffCode(v?.tariffCode || null),
                  }}
                >
                  <Form.Item
                    config={{
                      name: 'tariffCode',
                      label: 'Nom de Tarif',
                    }}
                  >
                    <Select
                      data={{
                        items:
                          tariffs?.list?.map(({ code, translation }) => ({
                            label: `${code} - ${
                              translation?.find((t) => t.lang === lang)?.name
                            }`,
                            value: code,
                          })) || [],
                      }}
                      config={{
                        mode: 'single',
                        width: 'full',
                        height: 'medium',
                      }}
                    />
                  </Form.Item>
                </Form>
              </Widget>
            </Zone.Area>
            <Zone.Area config={{ area: 'code' }}>
              <Widget
                state={
                  readTariffQuery.called
                    ? {
                        error: readTariffQuery.error,
                        loading: readTariffQuery.loading,
                        refetch: readTariffQuery.refetch,
                      }
                    : undefined
                }
                config={{
                  title: 'Configuration du Tarif',
                  extra: (
                    <div style={{ marginLeft: 15 }}>
                      {tariff && (
                        <Row config={{ horizontal: 'center', gap: 5 }}>
                          {/* <Badge
                            config={{
                              text:
                                isValidChangeTariff ||
                                valueChangeTariff === null
                                  ? 'Valide'
                                  : 'Invalide',
                              color:
                                isValidChangeTariff ||
                                valueChangeTariff === null
                                  ? 'success'
                                  : 'error',
                            }}
                          /> */}

                          <Badge
                            config={{
                              text: valueChangeTariffAndCompare
                                ? 'Modifiée'
                                : 'Non modifiée',
                              color: valueChangeTariffAndCompare
                                ? 'yell'
                                : 'disabled',
                            }}
                          />
                        </Row>
                      )}
                    </div>
                  ),
                }}
              >
                <Zone
                  config={{
                    zones: [['code'], ['button']],
                    rows: ['1fr', 'min-content'],
                    columns: ['1fr'],
                  }}
                >
                  <Zone.Area config={{ area: 'code' }}>
                    <Code
                      data={{
                        defaultValue: tariff ? tariff?.description : undefined,
                      }}
                      handleEvent={{
                        input: (v) => setValueChangeTariff(v),
                        isValid: (v) => {
                          setIsValidChangeTariff(v);
                        },
                      }}
                      config={{ lang: 'yaml', yMax: '100%', resize: true }}
                    />
                  </Zone.Area>
                  <Zone.Area config={{ area: 'button' }}>
                    <Button
                      handleEvent={{
                        click: async () => {
                          await updateTariffCall({
                            variables: {
                              input: {
                                tariffId: tariff?._id!,
                                description: valueChangeTariff,
                              },
                            },
                          });
                        },
                      }}
                      config={{
                        text: 'Modifier',
                        disabled: !(
                          isValidChangeTariff && valueChangeTariffAndCompare
                        ),
                      }}
                    />
                  </Zone.Area>
                </Zone>
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
        <Zone.Area config={{ area: 'right' }}>
          <Zone
            config={{
              zones: [['filtres'], ['chart']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'filtres' }}>
              <Widget config={{ title: 'Filtres' }}>
                <FilterExpanded
                  handleEvent={{
                    change: (v) => {
                      setIsValidFilter(!!v?.startDate);
                    },
                    submit: async (v) => {
                      if (
                        !v?.startDate ||
                        !(valueChangeTariff || tariff?.description)
                      )
                        return;

                      await simulateTariffCall({
                        variables: {
                          input: {
                            startDate: v?.startDate as string,
                            description:
                              valueChangeTariff || tariff?.description!,
                          },
                        },
                      });
                    },
                  }}
                >
                  <FilterExpanded.Fields>
                    <Form.Item
                      config={{
                        name: 'startDate',
                        label: 'Date de début',
                      }}
                    >
                      <DatePicker
                        config={{
                          mode: 'single',
                          format: 'datetime',
                          width: 'xlarge',
                          height: 'medium',
                        }}
                      />
                    </Form.Item>
                  </FilterExpanded.Fields>
                  <FilterExpanded.Actions>
                    <Button
                      config={{
                        disabled: !isValidFilter,
                        text: 'Simuler',
                        type: { value: 'submit' },
                      }}
                    />
                  </FilterExpanded.Actions>
                </FilterExpanded>
              </Widget>
            </Zone.Area>
            <Zone.Area config={{ area: 'chart' }}>
              <Widget
                state={
                  simulateTariffQuery.called
                    ? {
                        error: simulateTariffQuery.error,
                        loading: simulateTariffQuery.loading,
                        refetch: simulateTariffQuery.refetch,
                      }
                    : undefined
                }
                config={{ title: 'Courbe Tarifaire' }}
              >
                {simulateTariff && tariff ? (
                  <TariffChart
                    config={{
                      currency: tariff.currency,
                      now: new Date(simulateTariff.now),
                    }}
                    data={simulateTariff.table
                      .reduce(
                        (
                          acc: Array<{
                            d: number;
                            n?: string | null;
                            dbg?: Array<string> | null;
                            a: number;
                            l: boolean;
                            dt: string;
                            m?: string | null;
                          }>,
                          t,
                        ) => [
                          ...acc,
                          {
                            ...t,
                            a:
                              simulateTariff.table
                                .slice(0, acc.length)
                                .reduce((total, item) => total + item.a, 0) +
                              t.a,
                            d:
                              simulateTariff.table
                                .slice(0, acc.length)
                                .reduce(
                                  (total, item) => total + item.d * 1000,
                                  0,
                                ) +
                              t.d * 1000,
                          },
                        ],
                        [],
                      )
                      .map((item) => ({
                        a: item.a,
                        date: item.d,
                        n: item.n || null,
                        dbg: item.dbg || null,
                        l: item.l,
                        dt: item.dt,
                        m: item.m || null,
                      }))}
                  />
                ) : (
                  <Empty config={{ mode: { name: 'noData' } }}></Empty>
                )}
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
