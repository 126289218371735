import {
  FilterExpanded,
  Form,
  Languages,
  Maps,
  MapsType,
  NullablePartial,
  Page,
  Select,
  useLazyQuery,
  useMutation,
  useMyUrl,
  useQuery,
  useTranslation,
  Widget,
  Write,
  Zone,
} from '@gimlite/watermelon';
import * as GeoJSON from 'geojson';
import { useEffect, useMemo, useState } from 'react';
import {
  LayerEntity,
  Mutation,
  MutationUpsertLayersArgs,
  Query,
  QueryClientArgs,
  QueryClientsArgs,
  QueryFindLayersArgs,
  QuerySearchTariffsArgs,
  ShapeEnum,
} from '../../../../client/graphql';
import { sortAlphabetically } from '../../../common/array';
import { ZoneModal } from '../../../components/zone-modal/zone-modal.component';
import { clientGql } from '../gql/client.gql';
import { clientsGql } from '../gql/clients.gql';
import { findLayersGql } from '../gql/findLayers.gql';
import { searchTariffsGql } from '../gql/searchTariffs.gql';
import { upsertLayersGql } from '../gql/upsertLayers.gql';
import './carto.scss';

type PropertiesDefinition = {
  _id: string;
  zoneName: string | null;
  zoneCode: string | null;
  description: string | null;
  tariffCode: string | null;
} & MapsType.PropertiesBase;

const layerToGeoJSON = ({
  data,
  lang,
}: {
  data: LayerEntity[];
  lang: Languages;
}): GeoJSON.FeatureCollection<
  GeoJSON.Polygon | GeoJSON.MultiPolygon,
  PropertiesDefinition
> => {
  return {
    type: 'FeatureCollection',
    features: data.map((layer) => {
      const currentTranslation = layer.translation?.find(
        (t) => t.lang === lang,
      );

      return {
        type: 'Feature',
        geometry: {
          type: layer.shape.type,
          coordinates: layer.shape.coordinates,
        },
        properties: {
          _id: layer._id,
          description: currentTranslation?.description || null,
          tariffCode: layer.tariffCode || null,
          zoneName: currentTranslation?.name || null,
          zoneCode: layer.code || null,
          strokeColor: layer.properties.stroke || null,
          strokeWeight: layer.properties.strokeWidth || null,
          strokeOpacity: layer.properties.strokeOpacity || null,
          fillColor: layer.properties.fill || null,
          fillOpacity: layer.properties.fillOpacity || null,
          zIndex: layer.level,
        },
      };
    }),
  };
};

const GeoJSONToLayer = ({
  data,
  clientId,
  lang,
}: {
  data: GeoJSON.FeatureCollection<
    GeoJSON.Polygon | GeoJSON.MultiPolygon,
    PropertiesDefinition
  >;
  clientId: string;
  lang: Languages;
}): NullablePartial<LayerEntity>[] => {
  return data.features.map((feature) => {
    const properties = feature.properties;

    return {
      _id: properties._id,
      shape: {
        type: feature.geometry.type as ShapeEnum,
        coordinates: feature.geometry.coordinates,
      },
      clientId,
      tariffCode: properties.tariffCode,
      translation: [
        {
          lang,
          name: properties.zoneName!,
          description: properties.description,
        },
      ],
      code: properties.zoneCode,
      properties: {
        fill: properties.fillColor ?? null,
        fillOpacity: properties.fillOpacity ?? null,
        stroke: properties.strokeColor ?? null,
        strokeOpacity: properties.strokeOpacity ?? null,
        strokeWidth: properties.strokeWeight ?? null,
      },
      category: 'PARKING_RIGHT',
      level: properties.zIndex,
    };
  });
};

export const Carto = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl<{ clientId: string }>();
  const [refresh, setRefresh] = useState<string | null>(null);
  const { lang } = useTranslation();
  const clientsState = useQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    variables: { limit: 300 },
  });

  const [clientCall, clientState] = useLazyQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql);

  const [searchTariffsCall, searchTariffsState] = useLazyQuery<
    { searchTariffs: Query['searchTariffs'] },
    QuerySearchTariffsArgs
  >(searchTariffsGql);

  const [findLayersCall, findLayersState] = useLazyQuery<
    { findLayers: Query['findLayers'] },
    QueryFindLayersArgs
  >(findLayersGql);

  const [upsertLayersCall, upsertLayersState] = useMutation<
    { upsertLayers: Mutation['upsertLayers'] },
    MutationUpsertLayersArgs
  >(upsertLayersGql, {
    notification: {
      success: 'Les zones ont été mises à jour avec succès',
      error: 'Une erreur est survenue lors de la mise à jour des zones',
      loading: 'Mise à jour des zones en cours ...',
    },
  });

  const clients = clientsState?.data?.clients || null;
  const client = clientState.data?.client;
  const tariffs = searchTariffsState.data?.searchTariffs || null;
  const layers = findLayersState.data?.findLayers || null;
  const upsertLayers = upsertLayersState.data?.upsertLayers || null;
  const tariffsList = useMemo(() => {
    if (!tariffs) return [];

    return tariffs.list.map((tariff) => ({
      name:
        tariff.translation?.find((t) => t.lang === lang)?.name || tariff.code,
      code: tariff.code,
    }));
  }, [tariffs, lang]);

  const layersFormatted = useMemo(() => {
    if (!layers || !lang) return null;

    return layerToGeoJSON({ data: layers, lang });
  }, [layers, lang]);

  const currentPosition = useMemo(() => {
    if (!client) return null;

    const [lng, lat] = client.center;

    return { lat, lng };
  }, [client]);

  useEffect(() => {
    if (!getParamsUrl?.clientId) return;

    clientCall({ variables: { clientId: getParamsUrl.clientId } });
    searchTariffsCall({
      variables: { clientId: getParamsUrl.clientId, limit: 300 },
    });
    findLayersCall({
      variables: { clientId: getParamsUrl.clientId },
    });
  }, [getParamsUrl?.clientId]);

  return (
    <Page>
      <Widget
        state={{
          error: findLayersState.error,
          loading: findLayersState.loading,
          refetch: findLayersState.refetch,
        }}
        config={{
          title: 'Cartographie (terrain de jeu)',
          subtitle:
            "Les positions des villes sont souvents inexactes. L'édition des mutlipolygons n'est pas opérationnelle.",
        }}
      >
        <Zone
          config={{
            zones: [['filters'], ['maps']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filters' }}>
            <FilterExpanded
              data={{
                value: {
                  clientId: getParamsUrl?.clientId,
                },
              }}
              handleEvent={{
                change: (values: any) => {
                  setParamsUrl({ ...getParamsUrl, ...values });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'clientId',
                    label: 'Clients',
                  }}
                >
                  <Select
                    data={{
                      items: clients
                        ? sortAlphabetically(
                            clients.list.map(({ slug, _id }: any) => ({
                              label: slug,
                              value: _id,
                            })),
                            'label',
                          )
                        : [],
                    }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'maps' }}>
            {getParamsUrl?.clientId && currentPosition ? (
              <Maps<PropertiesDefinition>
                drawing={{
                  modes: {
                    polygon: true,
                  },
                  onSubmit: async (value) => {
                    await upsertLayersCall({
                      variables: {
                        input: {
                          clientId: getParamsUrl.clientId!,
                          layers: GeoJSONToLayer({
                            data: value,
                            clientId: getParamsUrl.clientId!,
                            lang: lang!,
                          }),
                        },
                      },
                    });

                    await findLayersState.refetch();

                    setRefresh(crypto.randomUUID());
                  },
                }}
                loader={{
                  loading: findLayersState.loading,
                }}
                refresh={refresh}
                data={layersFormatted}
                infoWindow={{
                  content: ({ overlay, mode }) => {
                    return (
                      <ZoneModal
                        tariffList={tariffsList}
                        mode={mode}
                        type={overlay.type}
                        zoneName={overlay.allProperties?.zoneName}
                        zoneCode={overlay.allProperties?.zoneCode}
                        tariffCode={overlay.allProperties?.tariffCode}
                        description={overlay.allProperties?.description}
                        fillColor={overlay.allProperties?.fillColor}
                        strokeColor={overlay.allProperties?.strokeColor}
                        fillOpacity={overlay.allProperties?.fillOpacity}
                        strokeOpacity={overlay.allProperties?.strokeOpacity}
                        strokeWeight={overlay.allProperties?.strokeWeight}
                        zIndex={overlay.allProperties?.zIndex}
                        onChange={(value) =>
                          overlay.onChange({
                            ...overlay.allProperties,
                            ...value,
                          })
                        }
                        onDelete={() => {
                          overlay.onDelete(overlay.building);
                        }}
                        onClose={overlay.onClose}
                      />
                    );
                  },
                }}
                defaultPosition={currentPosition}
              />
            ) : (
              <div className="maps-empty">
                <Write
                  data={{
                    item: 'Veuillez sélectionner un client ...',
                  }}
                  config={{
                    mode: 'value-large',
                  }}
                ></Write>
              </div>
            )}
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
