import { gql } from '@apollo/client';

export const clientGql = gql`
  query client($clientId: String!) {
    client(clientId: $clientId) {
      _id
      center
      code
    }
  }
`;
