import EditorMonaco, { useMonaco } from '@monaco-editor/react';
import { useEffect, useRef } from 'react';
import { useRootStore } from '../../hook/useRootStore.hook';
import { SkeletonBlock } from '../skeleton/skeleton.component';
import './code.component.scss';

export declare namespace CodeType {
  type Props = {
    className?: string;
    handleEvent?: {
      isValid?: (value: boolean) => void;
      input?: (value: string) => void;
      handleErrors?: (errors: EditorSyntaxError[]) => void;
    };
    data?: {
      defaultValue?: string;
    };
    config?: {
      lang?: string;
      placeholder?: string;
      minLength?: number;
      maxLength?: number;
      resize?: boolean;
      yMax?: string | number;
      readOnly?: boolean;
      jsonSchema?: any;
      jsonSchemaUri?: string;
    };
  };
}

export type EditorSyntaxError = {
  _id: string;
  severity: number;
  message: string;
  startLineNumber: number;
  startColumn: number;
};

export const Code = ({
  handleEvent: { isValid, input, handleErrors } = {},
  config: {
    lang = 'text',
    yMax = 'initial',
    readOnly = false,
    jsonSchema,
    jsonSchemaUri,
  } = {},
  data: { defaultValue } = {},
  className = '',
}: CodeType.Props) => {
  const monaco = useMonaco();
  const { ThemeStore } = useRootStore();

  useEffect(() => {
    if (input && defaultValue) {
      try {
        if (lang === 'json') JSON.parse(defaultValue);
        isValid?.(true);
        input?.(defaultValue);
      } catch {}
    }
  }, []);

  useEffect(() => {
    if (monaco && lang === 'json' && jsonSchema && jsonSchemaUri) {
      monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: jsonSchemaUri,
            fileMatch: ['*'],
            schema: {
              ...jsonSchema,
              additionalProperties: false,
            },
          },
        ],
      });
    }
  }, [jsonSchema, jsonSchemaUri, lang, monaco]);

  return (
    <div style={{ height: yMax }} className={`code ${className}`}>
      <EditorMonaco
        onChange={(value: string | undefined) => {
          try {
            if (value) {
              input && input(value);
            }
          } catch (error) {
            console.error(error);
          }
        }}
        onValidate={(markers) => {
          if (markers.length > 0) {
            isValid?.(false);
            handleErrors?.(
              markers.map((marker) => ({
                _id: crypto.randomUUID(),
                ...marker,
              })),
            );
          } else {
            handleErrors?.([]);
            isValid?.(true);
          }
        }}
        options={{
          scrollbar: { verticalScrollbarSize: 4 },
          minimap: { enabled: true },
          disableLayerHinting: true,
          cursorSurroundingLinesStyle: 'all',
          readOnly,
          automaticLayout: true,
        }}
        loading={<SkeletonBlock />}
        theme={`vs-${ThemeStore.theme}`}
        height={yMax}
        language={lang}
        value={defaultValue}
      />
    </div>
  );
};
