import { DateTime } from "luxon"

export function defaultSorter<T>(key: keyof T) {
    return {
        sorter: (a: T, b: T) =>
            `${a[key]}`.toLowerCase() >
                `${b[key]}`.toLowerCase()
                ? -1
                : 1,
    }
}

export function dateSorter<T>(key: keyof T) {
    return {
        sorter: (a: T, b: T) =>
            DateTime.fromISO(a[key] as string) >
                DateTime.fromISO(b[key] as string)
                ? -1
                : 1,
    }
}