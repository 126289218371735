import { gql } from '@apollo/client';

export const readTariffGql = gql`
  query readTariff($input: ReadTariffDto!) {
    readTariff(input: $input) {
      _id
      code
      description
      currency
    }
  }
`;
