import { gql } from '@apollo/client'

export const terminalActionsGql = gql`
    query terminalActions(
        $page: Int
        $limit: Int
        $order: String
        $type: String,
        $status: String
    ) {
        terminalActions(
            page: $page,
            limit: $limit,
            order: $order,
            type: $type,
            status: $status
        ) {
            list {
                _id
                terminalId
                site {
                    name
                }
                type
                status
                start
                deadline
            }

            paging {
                count
                current
                limit
            }
        }
    }
`